import React, {useState,useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate }  from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { Button, View, Input, TextInput, Footer, PasswordInput, ComplianceSummaryPDF } from '../../../../components'
import { saveAs } from 'file-saver';
import { PDFDownloadLink } from "@react-pdf/renderer";

const ComplianceChecklist = observer(({compObj, id, complianceSaved, IDNumber}) => {
    const store = useStore();
    const [complianceObject, setComplianceObject] = useState({});
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    useEffect(() => {
        // store.setCurrentLocation('');
        setComplianceObject(compObj);
    }, []);

    function checklistHeaderChanged(e) {
        let id = e.currentTarget.id;
        let tempObj = complianceObject;
        tempObj[id] = e.target.value;
        setComplianceObject(tempObj);
        forceUpdate();
    }
    function complianceQuestionCheckboxChanged(e) {
        let id = e.currentTarget.id;
        let idArr = id.split(' ');
        let tempObj = complianceObject;
        if (idArr[1] === 'true'){
            tempObj.Questions[idArr[0]].Complete = true;
        }else{
            tempObj.Questions[idArr[0]].Complete = false;
        }
        setComplianceObject(tempObj);
        forceUpdate();
    }

    function complianceQuestionCommentsChanged(e){
        let id = e.currentTarget.id;
        let tempObj = complianceObject;
        tempObj.Questions[id].Comments = e.target.value;
        setComplianceObject(tempObj);
        forceUpdate();
    }

    function complianceFicaCheckboxChanged(e){
        let id = e.currentTarget.id;
        let idArr = id.split(' ');
        let tempObj = complianceObject;
        if (idArr[1] === 'true'){
            tempObj.Fica[idArr[0]].Complete = true;
        }else{
            tempObj.Fica[idArr[0]].Complete = false;
        }
        setComplianceObject(tempObj);
        forceUpdate();
    }

    function complianceFicaCommentsChanged(e){
        let id = e.currentTarget.id;
        let tempObj = complianceObject;
        tempObj.Fica[id].Comments = e.target.value;
        setComplianceObject(tempObj);
        forceUpdate();
    }

    function additionalCommentsChanged(e){
        let tempObj = complianceObject;
        tempObj.Comments = e.target.value;
        setComplianceObject(tempObj);
        forceUpdate();
    }

    const submitChecklist = async() => {
        store.setLoading(true);
        let data = {
            updates: {Checklist: complianceObject},
            IDNumber: IDNumber
            
        }
        let response = await store.updateComplianceChecklist(id, data);
        if (!response){
            window.alert('Error updating checklist');
            store.setLoading(false);
        }else{
            complianceSaved(complianceObject, id);
        }
    }

    if (Object.keys(complianceObject).length === 0) {
        return (
            <div></div>
        )
    }
    if (isMobile()){
        return(
            <div></div>
        )
    }else{
        return (
            <div className='AEXComplianceSelectedUserContainer clientFileComplianceChecklist'>
                <p className='complianceHeader'>Client File Compliance Checklist</p>
                <div className='complianceHeaderFieldContainers'>
                    <div style={{width: '80%'}}>
                        <TextInput placeholder='Client Name' value={complianceObject.ClientName}/>
                    </div>
                    <div style={{width: '20%'}}>
                        <TextInput placeholder='Date' value={`${complianceObject.Date.getDate()}/${complianceObject.Date.getMonth() + 1}/${complianceObject.Date.getFullYear()}`}/>
                    </div>
                </div>
                <div className='complianceHeaderFieldContainers'>
                    <div style={{width: '50%'}}>
                        <TextInput placeholder='Type Of Business Written' id='TypeOfBusinessWritten' onChange={checklistHeaderChanged} value={complianceObject.TypeOfBusinessWritten}/>
                    </div>
                    <div style={{width: '50%'}}>
                        <TextInput placeholder='Advisor Name' id='AdvisorName' onChange={checklistHeaderChanged} value={complianceObject.AdvisorName}/>
                    </div>
                </div>

                <div className='complianceChecklistTableContainer'>
                    <div className='complianceChecklistTableHeader'>
                        <p style={{width: '40%', textAlign: 'left'}}>Name</p>
                        <p style={{width: '10%', textAlign: 'center'}}>No</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Yes</p>
                        <p style={{width: '40%', textAlign: 'right'}}>Comments</p>
                    </div>
                    <div className='complianceChecklistTableHeaderDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Letter of Introduction given to client</p>
                        <div className='complianceChecklistTableCheckbox'><img id='LetterOfIntro false' src={`${(!complianceObject.Questions.LetterOfIntro.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='LetterOfIntro true' src={`${(complianceObject.Questions.LetterOfIntro.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='LetterOfIntro' value={complianceObject.Questions.LetterOfIntro.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Letter of Engagement</p>
                        <div className='complianceChecklistTableCheckbox'><img id='LetterOfEngagement false' src={`${(!complianceObject.Questions.LetterOfEngagement.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='LetterOfEngagement true' src={`${(complianceObject.Questions.LetterOfEngagement.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='LetterOfEngagement' value={complianceObject.Questions.LetterOfEngagement.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Brokers Note/ Letter of Authority</p>
                        <div className='complianceChecklistTableCheckbox'><img id='BrokersNote false' src={`${(!complianceObject.Questions.BrokersNote.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='BrokersNote true' src={`${(complianceObject.Questions.BrokersNote.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='BrokersNote' value={complianceObject.Questions.BrokersNote.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>POPIA Notice and Consent Form</p>
                        <div className='complianceChecklistTableCheckbox'><img id='Popia false' src={`${(!complianceObject.Questions.Popia.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='Popia true' src={`${(complianceObject.Questions.Popia.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='Popia' value={complianceObject.Questions.Popia.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Fact finder/ Information Gathering Atwork FNA Capture Form</p>
                        <div className='complianceChecklistTableCheckbox'><img id='FactFinder false' src={`${(!complianceObject.Questions.FactFinder.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='FactFinder true' src={`${(complianceObject.Questions.FactFinder.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='FactFinder' value={complianceObject.Questions.FactFinder.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Policy Schedule</p>
                        <div className='complianceChecklistTableCheckbox'><img id='PolicySchedule false' src={`${(!complianceObject.Questions.PolicySchedule.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='PolicySchedule true' src={`${(complianceObject.Questions.PolicySchedule.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='PolicySchedule' value={complianceObject.Questions.PolicySchedule.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Risk Profile</p>
                        <div className='complianceChecklistTableCheckbox'><img id='RiskProfile false' src={`${(!complianceObject.Questions.RiskProfile.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='RiskProfile true' src={`${(complianceObject.Questions.RiskProfile.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='RiskProfile' value={complianceObject.Questions.RiskProfile.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Needs Analysis (electronic or paper based)</p>
                        <div className='complianceChecklistTableCheckbox'><img id='NeedsAnalysis false' src={`${(!complianceObject.Questions.NeedsAnalysis.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='NeedsAnalysis true' src={`${(complianceObject.Questions.NeedsAnalysis.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='NeedsAnalysis' value={complianceObject.Questions.NeedsAnalysis.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Single Needs Declaration</p>
                        <div className='complianceChecklistTableCheckbox'><img id='SingleNeedsDeclaration false' src={`${(!complianceObject.Questions.SingleNeedsDeclaration.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='SingleNeedsDeclaration true' src={`${(complianceObject.Questions.SingleNeedsDeclaration.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='SingleNeedsDeclaration' value={complianceObject.Questions.SingleNeedsDeclaration.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Comparative Quotations</p>
                        <div className='complianceChecklistTableCheckbox'><img id='ComparativeQuotation false' src={`${(!complianceObject.Questions.ComparativeQuotation.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='ComparativeQuotation true' src={`${(complianceObject.Questions.ComparativeQuotation.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='ComparativeQuotation' value={complianceObject.Questions.ComparativeQuotation.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Signed Client Advice Record (electronic or paperbased) Including Fees and Charges</p>
                        <div className='complianceChecklistTableCheckbox'><img id='SignedClientAdvice false' src={`${(!complianceObject.Questions.SignedClientAdvice.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='SignedClientAdvice true' src={`${(complianceObject.Questions.SignedClientAdvice.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='SignedClientAdvice' value={complianceObject.Questions.SignedClientAdvice.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Fully Completed Application Form</p>
                        <div className='complianceChecklistTableCheckbox'><img id='FullyCompletedApplication false' src={`${(!complianceObject.Questions.FullyCompletedApplication.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='FullyCompletedApplication true' src={`${(complianceObject.Questions.FullyCompletedApplication.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='FullyCompletedApplication' value={complianceObject.Questions.FullyCompletedApplication.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Signed Quotation</p>
                        <div className='complianceChecklistTableCheckbox'><img id='SignedQuotation false' src={`${(!complianceObject.Questions.SignedQuotation.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='SignedQuotation true' src={`${(complianceObject.Questions.SignedQuotation.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='SignedQuotation' value={complianceObject.Questions.SignedQuotation.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>RPAR and Disclosures (ASISA Form & Comparison document )</p>
                        <div className='complianceChecklistTableCheckbox'><img id='RPAR false' src={`${(!complianceObject.Questions.RPAR.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='RPAR true' src={`${(complianceObject.Questions.RPAR.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='RPAR' value={complianceObject.Questions.RPAR.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>TFS check - Confirmation of Status </p>
                        <div className='complianceChecklistTableCheckbox'><img id='TFS false' src={`${(!complianceObject.Questions.TFS.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='TFS true' src={`${(complianceObject.Questions.TFS.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceQuestionCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='TFS' value={complianceObject.Questions.TFS.Comments} onChange={complianceQuestionCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>
                </div>

                <p className='ficaHeader'>FICA</p>

                <div className='complianceChecklistTableContainer'>
                    <div className='complianceChecklistTableHeader'>
                        <p style={{width: '40%', textAlign: 'left'}}>Name</p>
                        <p style={{width: '10%', textAlign: 'center'}}>No</p>
                        <p style={{width: '10%', textAlign: 'center'}}>Yes</p>
                        <p style={{width: '40%', textAlign: 'right'}}>Comments</p>
                    </div>
                    <div className='complianceChecklistTableHeaderDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Face to Face or non-face to face</p>
                        <div className='complianceChecklistTableCheckbox'><img id='FaceToFace false' src={`${(!complianceObject.Fica.FaceToFace.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='FaceToFace true' src={`${(complianceObject.Fica.FaceToFace.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='FaceToFace' value={complianceObject.Fica.FaceToFace.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>S.A Identity Document - Certified Copy </p>
                        <div className='complianceChecklistTableCheckbox'><img id='ID false' src={`${(!complianceObject.Fica.ID.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='ID true' src={`${(complianceObject.Fica.ID.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='ID' value={complianceObject.Fica.ID.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Passport - Certified Copy</p>
                        <div className='complianceChecklistTableCheckbox'><img id='Passport false' src={`${(!complianceObject.Fica.Passport.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='Passport true' src={`${(complianceObject.Fica.Passport.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='Passport' value={complianceObject.Fica.Passport.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Utility Bill</p>
                        <div className='complianceChecklistTableCheckbox'><img id='Utility false' src={`${(!complianceObject.Fica.Utility.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='Utility true' src={`${(complianceObject.Fica.Utility.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='Utility' value={complianceObject.Fica.Utility.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Bank Statement/ Proof of Account</p>
                        <div className='complianceChecklistTableCheckbox'><img id='BankStatement false' src={`${(!complianceObject.Fica.BankStatement.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='BankStatement true' src={`${(complianceObject.Fica.BankStatement.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='BankStatement' value={complianceObject.Fica.BankStatement.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Salary Advice/ Payslip</p>
                        <div className='complianceChecklistTableCheckbox'><img id='Salary false' src={`${(!complianceObject.Fica.Salary.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='Salary true' src={`${(complianceObject.Fica.Salary.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='Salary' value={complianceObject.Fica.Salary.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Verification Declaration</p>
                        <div className='complianceChecklistTableCheckbox'><img id='VerificationDeclaration false' src={`${(!complianceObject.Fica.VerificationDeclaration.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='VerificationDeclaration true' src={`${(complianceObject.Fica.VerificationDeclaration.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='VerificationDeclaration' value={complianceObject.Fica.VerificationDeclaration.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>SARS NO.</p>
                        <div className='complianceChecklistTableCheckbox'><img id='SARS false' src={`${(!complianceObject.Fica.SARS.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='SARS true' src={`${(complianceObject.Fica.SARS.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='SARS' value={complianceObject.Fica.SARS.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>

                    <div className='complianceChecklistTableRow'>
                        <p style={{width: '40%', textAlign: 'left'}} className='questionHeader'>Source of funds due diligence</p>
                        <div className='complianceChecklistTableCheckbox'><img id='SourceOfFunds false' src={`${(!complianceObject.Fica.SourceOfFunds.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceFalse.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div className='complianceChecklistTableCheckbox'><img id='SourceOfFunds true' src={`${(complianceObject.Fica.SourceOfFunds.Complete) ? 'https://wealthcdn.aions.co.za/compliance/checklist/complianceTrue.svg' : 'https://wealthcdn.aions.co.za/compliance/checklist/complianceEmpty.svg'}`} onClick={complianceFicaCheckboxChanged} /></div>
                        <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}><TextInput placeholder='Comments' id='SourceOfFunds' value={complianceObject.Fica.SourceOfFunds.Comments} onChange={complianceFicaCommentsChanged} /></div>
                    </div>
                    <div className='complianceChecklistTableRowDivider'></div>
                </div>

                <p className='ficaHeader'>Risk Rating</p>
                <div className='additionalCommentsHeader'>
                    <p className='commentsHeader'>Risk rating of client & why clients are rated as HIGH, MEDIUM, or LOW risk</p>
                    <textarea className='commentsTextArea' placeholder='Risk Rating' onChange={additionalCommentsChanged} value={complianceObject.Comments}/>
                </div>
                <Button style={{marginBottom: '20px'}} onClick={submitChecklist}>Update</Button>

            </div>
        )
    }
})

export default ComplianceChecklist