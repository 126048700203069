import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { isMobile } from "is-mobile";
import { useStore } from '../../../stores/Store';
import './style.scss';
import { FaTimes } from "react-icons/fa";
import { IoFilter } from "react-icons/io5";
import { TextInput, View, SelectInput, Button } from '../../../components';



const AdminTransactions = observer(({ }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [Transactions, setTransactions] = useState([]);
    const [SuccessfulTransactions, setSuccessfulTransactions] = useState([]);
    const [AllCompanies, setAllCompanies] = useState([]);
    const [SearchValue, setSearchValue] = useState('');
    const [SelectedTransaction, setSelectedTransaction] = useState({});
    const [Filters, setFilters] = useState({
        StartDate: null,
        EndDate: null,
        Company: 'Company',
        Status: 'Status'
    }); 
    const [FinalFilters, setFinalFilters] = useState({
        StartDate: null,
        EndDate: null,
        Company: 'Company',
        Status: 'Status'
    }); 
    const [ShowFilter, setShowFilter] = useState(false);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [TotalSuccessfulAmount, setTotalSuccessfulAmount] = useState(0);
    const statusImages = {
        Failed: "https://wealthcdn.aions.co.za/AccountManager/DocumentDeclined.svg",
        Complete: "https://wealthcdn.aions.co.za/AccountManager/DocumentVerified.svg",
        Pending: "https://wealthcdn.aions.co.za/AccountManager/DocumentPending.svg"
    }
   
    

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async() => {
        store.setLoading(true);
        let trn = await store.getTransactions();
        console.log(trn);
        let Companies = await store.getCompanies();
        setAllCompanies(Companies);
        trn.sort((a, b) => (new Date(a.Date)) - (new Date(b.Date)));
        let successfulTrns = trn.filter((t) => t.Status === 'Complete');

        let totalInvested = 0;
        let totalTransactions = 0;
        for (let i = 0; i < trn.length; i++) {
        let currentTransaction = trn[i];
        totalTransactions = totalTransactions + currentTransaction.Amount;
        if (currentTransaction.Status === 'Pending' || currentTransaction.Status === 'Failed') {
            trn[i].CurrentValue = 0;
            continue;
        }
        if (currentTransaction.CompanyName === 'Rand'){
            trn[i].CurrentValue = currentTransaction.Amount;
            continue;
        }
        
        let compArr = Companies.filter((a) => a.Name === currentTransaction.CompanyName);
        if (compArr.length === 0){
            trn[i].CurrentValue = currentTransaction.Amount;
            continue;
        }
        let currentCompany = compArr[0];
        let feeArr = trn.filter((t) => t.TransactionReference === (`Fee_Refund_${currentTransaction._id}`));
        if (feeArr.length > 0){
            currentTransaction.Amount = currentTransaction.Amount - feeArr[0].Amount;
        }
        let trnDate = new Date(currentTransaction.Date);
        let currentDate = new Date();
        let timeElapsedInMilliseconds = currentDate - trnDate;
        let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
        let interestRate = 0.10;
        let interestVal = currentTransaction.Amount * (1 + interestRate) ** timeElapsedInYears;
        let coinVal = Number(currentTransaction.ReservedCoins) * currentCompany.Price;
        if (interestVal > coinVal) {
            trn[i].CurrentValue = Number(interestVal.toFixed(2));
        }else{
            trn[i].CurrentValue = Number(coinVal.toFixed(2));
        }
        totalInvested = totalInvested + trn[i].Amount;

        }
        setTotalAmount(totalTransactions);
        setTotalSuccessfulAmount(totalInvested);
        setTransactions(trn);
        setSuccessfulTransactions(successfulTrns);
        forceUpdate()
        store.setLoading(false);
    }

    function searchValueChanged(e){
        setSearchValue(e.target.value);
    }

    function transactionSelected(e){
        setSelectedTransaction(Transactions[Number(e.currentTarget.id)]);
        forceUpdate();
    }

    function closeTransaction(){
        setSelectedTransaction({});
        forceUpdate();
    }

    function toggleFilter(){
        if (!ShowFilter){
            setFilters({...FinalFilters});
        }
        setShowFilter(!ShowFilter);
        forceUpdate();
    }

    function selectFilterChanged(e){
        let tempFilter = Filters;
        tempFilter[e.currentTarget.id] = e.target.value;
        setFilters(tempFilter);
        forceUpdate();
    }

    function filterDateChanged(e){
        let tempFilter = Filters;
        let date = new Date(e.target.value);
        if (e.currentTarget.id === 'StartDate'){
            date.setSeconds(1);
            date.setMinutes(0);
            date.setHours(0);
            if (tempFilter.EndDate !== null && (date) > tempFilter.EndDate){
                window.alert('Start date cannot be after end date.');
                return
            }
        }else{
            date.setSeconds(59);
            date.setMinutes(59);
            date.setHours(23);
            if (tempFilter.StartDate !== null && (date) < tempFilter.StartDate){
                window.alert('End date cannot be before start date.');
                return
            }
        }
        tempFilter[e.currentTarget.id] = date;
        setFilters(tempFilter);
        forceUpdate();
    }

    function applyFilter(){
        setFinalFilters(Filters);
        toggleFilter();
    }

    function clearFilters(){
        setFilters({
            StartDate: null,
            EndDate: null,
            Company: 'Company',
            Status: 'Status'
        })
        setFinalFilters({
            StartDate: null,
            EndDate: null,
            Company: 'Company',
            Status: 'Status'
        })
        toggleFilter();
    }
    function formatDate(date) {
        // date.setHours(date.getHours()); // Add 2 hours
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;   
      
      }

    

    if (isMobile() || store.isScreenMobile()) {
        return (
          <View style={{height: `${store.screenHeight - 50}px`, justifyContent: 'space-between'}}>
          </View>
        );
    } else {
        return(
            <div className='adminTransactionContainer'>
                <div className='headerSection'>
                    <p className='userHeader'>Transactions</p>
                    <div style={{width: '50%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                        {/* <div style={{width: '40%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}> */}
                            <TextInput placeholder='Search' value={SearchValue} onChange={searchValueChanged} style={{width: 'calc(95% - 65px)'}} />
                        {/* </div> */}
                        <div style={{width: '65px', height: '60px', backgroundColor: '#FFFFFF40', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', cursor: 'pointer'}} onClick={toggleFilter}>
                            <IoFilter style={{color: 'white', fontSize: '30px'}} />
                        </div>
                    </div>
                </div>
                <div className='userTable'>
                    <div className='tableHeaderRow'>
                        <p style={{width: '15%', textAlign: 'left'}}>Date</p>
                        <p style={{width: '25%', textAlign: 'left'}}>ID Number</p>
                        <p style={{width: '30%', textAlign: 'left'}}>Company</p>
                        <p style={{width: '20%', textAlign: 'left'}}>Amount</p>
                        <p style={{width: '10%', textAlign: 'left'}}>Status</p>
                    </div>
                    <div className='tableHeaderDivider'></div>
                    {Transactions.map((transaction, index) => {
                    if (SearchValue !== '' && !transaction.IDNumber.includes(SearchValue)){
                        return
                    }
                    if (FinalFilters.StartDate !== null && (new Date(transaction.Date)) < FinalFilters.StartDate){
                        return
                    }
                    if (FinalFilters.EndDate !== null && (new Date(transaction.Date)) > FinalFilters.EndDate){
                        return
                    }
                    if (FinalFilters.Status !== 'Status' && transaction.Status !== FinalFilters.Status){
                        return
                    }
                    if (FinalFilters.Company !== 'Company' && transaction.CompanyName !== FinalFilters.Company){
                        return
                    }
                    return(
                        <>
                        <div className='tableContentRow' id={index} onClick={transactionSelected}>
                            <p style={{width: '15%', textAlign: 'left'}}>{(new Date(transaction.Date)).toLocaleDateString()}</p>
                            <p style={{width: '25%', textAlign: 'left'}}>{transaction.IDNumber || ''}</p>
                            <p style={{width: '30%', textAlign: 'left'}}>{transaction.CompanyName}</p>
                            <p style={{width: '20%', textAlign: 'left'}}>R{transaction.Amount.toFixed(2)}</p>
                            <div style={{width: '10%'}}>
                                <img src={statusImages[transaction.Status]} alt={transaction.Status}/>
                            </div>
                        </div>
                        <div className='tableContentDivider'></div>
                        </>
                    )
                    })}
                </div>
                <div className='usersSummaryContainer'>
                    <div className='userSummaryItem'>
                        <p className='header'>Total Transactions</p>
                        <p className='content'>{Transactions.length}</p>
                    </div>
                    <div className='userSummaryItem'>
                        <p className='header'>Total Amount</p>
                        <p className='content'>R{TotalAmount.toFixed(2)}</p>
                    </div>
                    <div className='userSummaryItem'>
                        <p className='header'>Complete Transactions</p>
                        <p className='content'>{SuccessfulTransactions.length}</p>
                    </div>
                    <div className='userSummaryItem'>
                        <p className='header'>Complete Amount</p>
                        <p className='content'>R{TotalSuccessfulAmount.toFixed(2)}</p>
                    </div>
                </div>

                {(Object.keys(SelectedTransaction).length > 0) ? 
                <div className='userDetailContainer'>
                    <div className='userDetailPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                            <FaTimes style={{color: 'white', fontSize: '25px', cursor: 'pointer'}} onClick={closeTransaction} />
                        </div>
                        <div className='popupContent'>
                            <div className='popupRow'>
                                <TextInput placeholder='ID Number' style={{width: '49%'}} value={SelectedTransaction.IDNumber} />
                                <div className='statusBlock'>
                                    <p>{SelectedTransaction.Status}</p>
                                </div>
                            </div>
                            <div className='popupRow'>
                                <TextInput placeholder='Transaction Date' style={{width: '32%'}} value={(new Date(SelectedTransaction.Date)).toLocaleDateString()} />
                                <TextInput placeholder='Transaction Amount' style={{width: '32%'}} value={`R${SelectedTransaction.Amount.toFixed(2)}`} />
                                <TextInput placeholder='Company' style={{width: '32%'}} value={SelectedTransaction.CompanyName} />
                            </div>
                            <div className='popupRow'>
                                <TextInput placeholder='Transaction Reference' style={{width: '49%'}} value={SelectedTransaction.TransactionReference || ''} />
                                <TextInput placeholder='Payment Request ID' style={{width: '49%'}} value={SelectedTransaction.PaymentRequestID || ''} />
                            </div>
                            <div className='popupRowDetail'>
                                <p className='header'>Transaction Logs</p>
                                {(Object.keys(SelectedTransaction).includes('Logs'))? 
                                <>
                                    {SelectedTransaction.Logs.map((log) => {
                                        return(
                                            <p className='content'>{log}</p>
                                        )
                                    })}
                                </>
                                : <p className='content'>None</p>}
                            </div>

                            
                        </div>
                    </div>
                </div>
                :null}
                {(ShowFilter) ? 
                <div className='userDetailContainer'>
                    <div className='filterPopup'>
                        <div className='popupHeader'>
                            <img src='https://wealthcdn.aions.co.za/assets/AEXLogo.webp' />
                            <FaTimes style={{color: 'white', fontSize: '25px', cursor: 'pointer'}} onClick={toggleFilter} />
                        </div>
                        <div className='filterContent'>
                            <div className='filterRow'>
                                <TextInput placeholder='Start Date' type='date' style={{width: '49%'}} value={(Filters.StartDate === null) ? Filters.StartDate : formatDate(Filters.StartDate)} id='StartDate' onChange={filterDateChanged} />
                                <TextInput placeholder='End Date' type='date' style={{width: '49%'}} value={(Filters.EndDate === null) ? Filters.EndDate : (new Date(Filters.EndDate)).toISOString().split('T')[0]} id='EndDate' onChange={filterDateChanged} />
                            </div>
                            <div className='filterRow'>
                                <SelectInput placeholder='Company' style={{width: '49%'}} id='Company' onChange={selectFilterChanged} value={Filters.Company}>
                                    <option value='Company' hidden>Company</option>
                                    {AllCompanies.map((company, index) => {
                                        return(
                                            <option value={company.Name}>{company.Name}</option>
                                        )
                                    })}
                                </SelectInput>
                                <SelectInput placeholder='Status' style={{width: '49%'}} id='Status' onChange={selectFilterChanged} value={Filters.Status}>
                                    <option value='Status' hidden>Status</option>
                                    <option value='Complete'>Complete</option>
                                    <option value='Pending'>Pending</option>
                                    <option value='Failed'>Failed</option>
                                </SelectInput>
                            </div>
                            <div className='filterRow'>
                                <Button style={{width: '49%', minWidth: '49%', backgroundColor: '#FFFFFF40'}} onClick={clearFilters}>Clear</Button>
                                <Button style={{width: '49%', minWidth: '49%'}} onClick={applyFilter}>Apply</Button>
                            </div>
                        </div>
                    </div>
                </div>
                :null}
            </div>
        )
    }
})
export default AdminTransactions