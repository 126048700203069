// require('dotenv').config();
import axios from 'axios';

// let middlewareUrl = 'https://s5f9ayyhh6.execute-api.eu-central-1.amazonaws.com/Prod/api';
let middlewareUrl = `${process.env.REACT_APP_MIDDLEWARE_URL}/api`;
// if (process.env.NODE_ENV === 'development') {
//   middlewareUrl = 'http://localhost:7061/api'
// }
axios.defaults.baseURL = middlewareUrl;

const getJWT = () => {
    const jwt = window.localStorage.getItem('jwt');
    if (jwt === null){
        return ''
    }else{
        return jwt
    }
}

let request = axios.create({
  baseUrl: middlewareUrl,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Bearer ${getJWT()}`
  },
});

// #region User
const User = {
  createUser: (data) => request.post('/user', data),
  createUserv2: (data) => request.post('/v2/user', data),
  checkIfUserExists: (id) => request.get(`/user/checkIfUserExists/${id}`),
  signupUpdateUser: (id, data) => request.patch(`/user/signup/${id}`, data),
  updateUser: (id, data) => request.patch(`/user/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  updateUserEmail: (id, data) => request.patch(`/user/email/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getPortfolio: (id) => request.get(`/user/portfolio/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getUser: (id) => request.get(`/user/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getUsers: () => request.get(`/user`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  resetPasswordRequest: (id) => request.get(`/user/resetPassword/${id}`),
  resetPassword: (id, data, jwt) => request.patch(`/user/resetPassword/${id}`, data, {headers: {Authorization: `Bearer ${jwt}`}}),
  createRandWallet: (id) => request.patch(`/user/randWallet/${id}`, {}, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  addWaitlist: (id) => request.post(`/user/waitlist/${id}`),
  getCompetetionStatus: (id) => request.get(`/user/comp/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getUserPurchaseComplete: (id) => request.get(`/user/purchaseComplete/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  createContract: (data) => request.post('/user/contract', data),
  checkVerified: (id) => request.get(`/user/verified/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getAdminBalances: () => request.get(`/user/adminBalances`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
};
// #endregion

// #region Auth
const Auth = {
  addPublicKey: (id) => request.get(`/auth/addWebauthn/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  addWebauthn: (id, data) => request.patch(`/auth/addWebauthn/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  checkWebauthn: (id) => request.get(`/auth/login/webauthn/${id}`),
  loginWebauthn: (id, data) => request.patch(`/auth/login/webauthn/${id}`, data),
  loginPassword: (id, data) => request.patch(`/auth/login/password/${id}`, data),
  getEidentLink: (id, data) => request.patch(`/auth/eident/link/${id}`, data),
  verifyEident: (id, data) => request.patch(`/auth/eident/verify/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getAuthQuestions: (data) => request.patch(`/auth/questions`, data),
  verifyAuthAnswers: (data) => request.patch(`/auth/answers`, data),
  changePassword: (id, data) => request.patch(`/auth/password/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getIdPhoto: (id) => request.get(`/auth/IdPhoto/${id}`),
  getDocument: (data) => request.patch(`/auth/esignaturedoc`, data),
  getCompliance: (id) => request.get(`/auth/compliance/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  loginGoogle: (data) => request.post(`/auth/login/google`, data),
  loginFacebook: (data) => request.post(`/auth/login/facebook`, data),
}
// #endregion

// #region Admin
const Admin = {
  getReservedCoins: (id) => request.get(`/admin/reserved/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getGaReport: (data) => request.post(`/admin/gaSession`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getOzowRecon: (data) => request.post(`/admin/ozow/recon`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),

};
// #endregion

// #region Company
const Company = {
  createCompany: (id, data) => request.post(`/company/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getCompanies: () => request.get(`/company`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getCompanyById: (id) => request.get(`/company/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  updateCompany: (id, data) => request.patch(`/company/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  buyCoin: (id, data) => request.post(`/company/buy/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  buyCoinRand: (id, data) => request.post(`/company/buy/rand/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  buyCoinNetcash: (id, data) => request.post(`/company/buy/netcash/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  buyCoinPeach: (id, data) => request.post(`/company/buy/peach/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  buyCoinManual: (data) => request.post(`/company/buy/admin`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
}
// #endregion

// #region Updates
const Updates = {
  getUpdates: () => request.get('/updates', {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getUpdateByID: (id) => request.get(`/updates/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  updateUpdates: (id, data) => request.patch(`/updates/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  createUpdate: (data) => request.post(`/updates`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  deleteUpdateById: (id) => request.delete(`/updates/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
};
// #endregion

// #region Transactions
const Transaction = {
  getTransactionById: (id) => request.get(`/transaction/id/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getTransactions: () => request.get(`/transaction`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  updateTransactionById: (id, data) => request.patch(`/transaction/id/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  refundFees: (id) => request.patch(`/transaction/fee/refund/${id}`, {}, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  sendPrevEmail: (id) => request.get(`/transaction/previousEmail/${id}`),
  adminCompleteTransaction: (id) => request.patch(`/transaction/admin/complete/${id}`, {}, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  adminFailTransaction: (id) => request.patch(`/transaction/admin/fail/${id}`, {}, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  adminApproveManualTransaction: (data) => request.patch(`/transaction/admin/manual/approve`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
};
const LandingPage = {
  getLandingPage: () => request.get(`/landingpage`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  updateLandingPage: (id, data) => request.patch(`/landingpage/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getAppVersion: () => request.get(`/landingpage/appVersion`, {headers: {Authorization: `Bearer ${getJWT()}`}}),

};
// #endregion 

// #region Entity
const Entity = {
  checkEntity: (data) => request.post(`/entity/check`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  createEntity: (data) => request.post(`/entity/create`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getEntityByAccountManager: (email) => request.get(`/entity/accountManager/${email}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getEntityById: (id) => request.get(`/entity/id/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  resendDirectorOnboardingEmail: (data) => request.put(`/entity/director/onboard`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  verifyDirectorLogin: (id, jwt) => request.get(`/entity/director/login/${id}`, {headers: {Authorization: `Bearer ${jwt}`}}),
  verifyDirectorOTP: (id, data) => request.post(`/entity/director/login/OTP/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  setDirectorPassword: (id, data) => request.post(`/entity/director/setPassword/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  createDirectorContract: (id, data) => request.post(`/entity/director/contract/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getEntityByDirector: (id) => request.get(`/entity/director/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  uploadCIPC: (id, data) => request.post(`/entity/CIPC/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  downloadDocument: (name) => request.get(`/entity/document/${name}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  deleteDocument: (name) => request.delete(`/entity/document/${name}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getPortfolio: (id) => request.get(`/entity/portfolio/${id}`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
};
// #endregion

// #region Compliance
const Compliance = {
  updateComplianceChecklist: (id, data) => request.patch(`/compliance/checklist/${id}`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getDocuments: () => request.get(`/compliance/document`, {headers: {Authorization: `Bearer ${getJWT()}`}}),
  getTransactionCompliance: (data) => request.put(`/compliance/transaction`, data, {headers: {Authorization: `Bearer ${getJWT()}`}}),

};
// #endregion
export {
    Auth,
    User,
    Company,
    Updates,
    Transaction,
    LandingPage,
    Admin,
    Entity,
    Compliance
  }