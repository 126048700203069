import React, { useState, useEffect } from "react";
import './style.scss';
import { Carousel } from "react-responsive-carousel";
import { useStore } from '../../../../stores/Store';
import PieChart from "./PieChart";
import AgeRangeHistogram from "./BarChart";
import LineGraph from "./SignUpLineGraph/SignUpLineGraph";

const SignUps = ({ id, users, filterData }) => {
    const store = useStore();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [carouselChange, setCarouselChange] = useState(false);
    const [LineDataArr, setLineDataArr] = useState([]);
    const [PieDataArr, setPieDataArr] = useState([]);
    const [BarDataArr, setBaraDataArr] = useState([]);
    const [TotSignUps, seTotSignUps] = useState(0);
    
    useEffect(() => {
        getIDs(users);
    }, [users]);

    const fillMissingDates = (dates) => {
        const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let monthData = {};
        let yearData = dates.yearData;


        for (let x = 0; x < dates.monthData.length; x++) {
            if (x !== 0) {
                const dateStr = dates.monthData[x].day;
                const day = parseInt(dateStr.slice(2, 4), 10); // Extract day
                const monthIndex = parseInt(dateStr.slice(0, 2), 10); // Extract month
                const monthName = months[monthIndex];

                if (!monthData[monthName]) {
                    monthData[monthName] = Array.from({ length: 31 }, (_, i) => ({ day: i + 1, Amount: 0 })); // Initialize days with Amount 0
                };

                monthData[monthName][day - 1].Amount += dates.monthData[x].Amount;
            };
        };

        return { monthData, yearData };
    };
    const groupAndSumCategories = (data) => {
        // Create an empty array to store the grouped results
        let result = [];
        // Loop through each item in the input data
        for (let i = 0; i < data.length; i++) {
            // Get the current item
            let currentItem = data[i];

            // Check if the current day's entry already exists in the result array
            let existingCategory = null;
            for (let j = 0; j < result.length; j++) {
                if (result[j].day === currentItem.day) {
                    existingCategory = result[j];
                    break;
                }
            }

            // If the entry exists, add the amount to the existing amount
            if (existingCategory) {
                existingCategory.Amount += currentItem.Amount;
            } else {
                // If the entry does not exist, create a new entry and add it to the result array
                result.push({ day: currentItem.day, Amount: currentItem.Amount });
            }
        };
        // Return the result array
        return result;
    };
    const groupAndSumCategoriesYearly = (data) => {
        let dataInRange = generateDateArray();

        for (let i = 0; i < dataInRange.length; i++) {
            for (let j = 0; j < data.length; j++) {
                if (data.map((item) => item.day).includes(dataInRange[i])) {
                } else {
                    data.push({ day: dataInRange[i], Amount: 0 })
                };
            };
        };

        // Create an empty array to store the grouped results
        let result = [];
        // Loop through each item in the input data
        for (let i = 0; i < data.length; i++) {
            // Get the current item
            let currentItem = data[i];

            // Check if the current day's entry already exists in the result array
            let existingCategory = null;
            for (let j = 0; j < result.length; j++) {
                if (result[j].day === currentItem.day) {
                    existingCategory = result[j];
                    break;
                }
            }

            // If the entry exists, add the amount to the existing amount
            if (existingCategory) {
                existingCategory.Amount += currentItem.Amount;
            } else {
                // If the entry does not exist, create a new entry and add it to the result array
                result.push({ day: currentItem.day, Amount: currentItem.Amount });
            }
        }
        const numericDates = result.map((item) => Number(item.day)).sort((a, b) => a - b).filter(item => item !== 0);
        // Return the result array
        return result.filter(item => item.day !== 0);
    };
    function generateDateArray() {
        const start = `${filterData.StartDate.slice(6, 10)}${filterData.StartDate.slice(3, 5)}`
        const end = `${filterData.EndDate.slice(6, 10)}${filterData.EndDate.slice(3, 5)}`
        let startYear = parseInt(start.substring(0, 4));
        let startMonth = parseInt(start.substring(4, 6));
        let endYear = parseInt(end.substring(0, 4));
        let endMonth = parseInt(end.substring(4, 6));

        let dateArray = [];
        while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
            let monthString = startMonth < 10 ? '0' + startMonth : startMonth;
            dateArray.push(`${startYear}${monthString}`);

            if (startMonth === 12) {
                startYear++;
                startMonth = 1;
            } else {
                startMonth++;
            }
        }
        return dateArray;
    }
    function removeLeadingTrailingZeros(data) {
        let start = 0;
        let end = data.length - 1;

        // Find the first non-zero Amount
        // while (start < data.length && data[start].Amount === 0) {
        //     start++;
        // };
        // // Find the last non-zero Amount
        while (end >= 0 && data[end].Amount === 0) {
            end--;
        };
        // If all amounts are zero, return an empty array
        if (start > end) {
            return [];
        };
        // Return the sub-array with leading and trailing zeros removed
        return data.slice(start, end + 1);
    };
    const sortDecade = (data) => {
        let obj = {};
        for (let i = 0; i < data.length; i++) {
            let year = Number(String(data[i].day).slice(0, 4));
            if (obj[year]) {
                obj[year] += data[i].Amount;
            } else {
                obj[year] = data[i].Amount;
            }
        }

        // Convert the object to an array of objects
        let objArr = [];
        for (let year in obj) {
            objArr.push({ day: Number(year), Amount: obj[year] });
        }
        while (objArr.length && objArr[0].Amount === 0) {
            objArr.shift();
        }
        return objArr;
    };
    const sortObjectByCalendarOrder = (obj) => {
        const calendarOrder = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const sortedObj = {};
        calendarOrder.forEach(month => {
            if (obj[month] !== undefined) {
                sortedObj[month] = obj[month];
            }
        });

        return sortedObj;
    };
    const groupByMonth = (data) => {
        const calendarOrder = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let dataArr = [];

        // Process the given data
        for (const month in data) {
            const monthIndex = calendarOrder.indexOf(month);
            const monthData = data[month];

            let totalAmount = 0;
            for (const item of monthData) {
                totalAmount += item.Amount;
            };

            dataArr.push({ day: calendarOrder[monthIndex], Amount: totalAmount });
        };

        // Add missing months with zero amounts
        const processedMonths = dataArr.map(item => item.day);
        for (let i = 1; i < calendarOrder.length; i++) {
            if (!processedMonths.includes(calendarOrder[i])) {
                dataArr.push({ day: calendarOrder[i], Amount: 0 });
            };
        };

        // Sort the dataArr by the month order
        dataArr.sort((a, b) => calendarOrder.indexOf(a.day) - calendarOrder.indexOf(b.day));

        ///Remove leading and trailing zero amounts
        // while (dataArr.length && dataArr[0].Amount === 0) {
        //     dataArr.shift();
        // }
        while (dataArr.length && dataArr[dataArr.length - 1].Amount === 0) {
            dataArr.pop();
        }
        return dataArr;
    };
    const handleCarouselOnChange = (index) => {
        setCarouselChange(index === 1);
    };
    function classifyGender(IDarray) {
        const currentYear = new Date().getFullYear();
        let female = { label: 'Female', value: 0, percentage: '', age: [] };
        let male = { label: 'Male', value: 0, percentage: '', age: [] };

        IDarray.forEach(id => {
            if (id.length >= 7) {
                let year = parseInt(id.slice(0, 2), 10);

                if (year >= 0 && year <= 17) {
                    // Assuming IDs with years 00-17 are from the 21st century (2000-2017)
                    year = 2000 + year;
                } else if (year >= 18 && year <= 99) {
                    // Assuming IDs with years 18-99 are from the 20th century (1918-1999)
                    year = 1900 + year;
                } else {
                    // Skip invalid years
                    return;
                };

                let age = currentYear - year;

                let genderDigit = parseInt(id[6], 10); // 7th digit is at index 6
                if (genderDigit >= 0 && genderDigit <= 4) {
                    female.value++;
                    female.age.push(age);
                    female.percentage = `${((female.value / IDarray.length) * 100).toFixed(2)}%`;
                } else if (genderDigit >= 5 && genderDigit <= 9) {
                    male.value++;
                    male.age.push(age);
                    male.percentage = `${((male.value / IDarray.length) * 100).toFixed(2)}%`;
                }
            }
        });

        let dataArr = [male, female];
        let AgeArr = [categorizeAges(dataArr[0].age), categorizeAges(dataArr[1].age)];

        setPieDataArr(dataArr);
        setBaraDataArr(AgeArr);
        return { male, female };
    };
    function categorizeAges(ages) {
        // Define the age ranges
        let ageRanges = {
            '18-24': 0,
            '25-34': 0,
            '35-44': 0,
            '45-54': 0,
            '55-64': 0,
            '65+': 0
        };

        // Categorize each age into the appropriate range
        ages.forEach(age => {
            if (18 <= age && age <= 24) {
                ageRanges['18-24'] += 1;
            } else if (25 <= age && age <= 34) {
                ageRanges['25-34'] += 1;
            } else if (35 <= age && age <= 44) {
                ageRanges['35-44'] += 1;
            } else if (45 <= age && age <= 54) {
                ageRanges['45-54'] += 1;
            } else if (55 <= age && age <= 64) {
                ageRanges['55-64'] += 1;
            } else {
                ageRanges['65+'] += 1;
            }
        });

        // Convert the object to the desired list of objects format
        let result = [];
        for (let range in ageRanges) {
            result.push({ label: range, value: ageRanges[range] });
        }

        return result;
    };
    const transformDataForGraph = (currentData) => {
        let year = null;
        let month = null;
        let day = null;
        let dates = {
            monthData: [],
            yearData: []
        };

        for (let i = 0; i < currentData.length; i++) {
            if (currentData[i].SignupDate) {
                year = new Date(currentData[i].SignupDate).getFullYear();
                month = new Date(currentData[i].SignupDate).getMonth() + 1;
                day = new Date(currentData[i].SignupDate).getDate();
                dates.monthData.push({
                    day: `${month >= 10 ? month : `0${month}`}${day >= 10 ? day : `0${day}`}`,
                    Amount: 1
                });
                dates.yearData.push({
                    day: `${year}${month >= 10 ? month : `0${month}`}`,
                    Amount: 1
                });
            };
        };
        return dates;
    };
    const getMonths = (data) => {
        const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        data.forEach(element => {
            element.day = months[Number(element.day.slice(4, 6))]
        });
        return data
    };
    const getIDs = async (data) => {
        if (data ===undefined){return}
        let IDsArr = [];
        for (let i = 0; i < data.length; i++) {
            IDsArr.push(data[i].IDNumber);
        };

        let dataArr = transformDataForGraph(users)
        let finalData = {
            monthData: groupAndSumCategories(dataArr.monthData),
            yearData: groupAndSumCategoriesYearly(dataArr.yearData)
        };

        finalData.monthData.unshift({ day: 0, Amount: 0 });
        finalData.yearData.unshift({ day: 0, Amount: 0 });
        finalData.monthData.sort((a, b) => a.day - b.day);
        finalData.yearData.sort((a, b) => a.day - b.day);
        finalData = fillMissingDates(finalData);

        let yearData = sortDecade(finalData.yearData);
        let latesYearData = finalData.yearData
        finalData = sortObjectByCalendarOrder(finalData.monthData);

        let newData = [];
        for (let i = 0; i < Object.values(finalData).length; i++) {
            for (let j = 0; j < Object.values(finalData)[i].length; j++) {
                newData.push(Object.values(finalData)[i][j]);
            };
        };

        newData = removeLeadingTrailingZeros(newData);
        const start = Number(`${filterData.StartDate.slice(6, 10)}${filterData.StartDate.slice(3, 5)}${filterData.StartDate.slice(0, 2)}`)
        const end = Number(`${filterData.EndDate.slice(6, 10)}${filterData.EndDate.slice(3, 5)}${filterData.EndDate.slice(0, 2)}`)

        if (end - start >= 120) {
            newData = getMonths(latesYearData.filter(item => item.day !== 0));
        };

        if (newData.length > 1095) {
            newData = yearData
        };

        setLineDataArr(newData);
        classifyGender(IDsArr);
        seTotSignUps(IDsArr.length);
        forceUpdate();
    };
    return (
        <div id={id} className="signups">
            <p className="signup_p">Sign Ups</p>
            <div className="graphs_div">

                <div className="bar_div">
                    <Carousel showStatus={false} onChange={handleCarouselOnChange} className={carouselChange ? 'carousel-root_inverse' : ''}>
                        <div className="svgline_div">
                            <LineGraph  width='100%' height='100%' data={LineDataArr} name={`Total Sign Ups: ${TotSignUps}`} />
                        </div>
                        <div className='svgchart_div' >
                            {Object.keys(PieDataArr).length === 0 ? null : <PieChart id={'signupPieChart'} width='100' height='100' data={PieDataArr} />}
                            {BarDataArr.length > 0 ? <AgeRangeHistogram data={BarDataArr} name={`Total Sign Ups: ${TotSignUps}`} /> : null}
                        </div>
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default SignUps;