import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react';
import { useStore } from '../../stores/Store';
import { isMobile } from 'is-mobile';
import './style.scss'
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { View, Footer, PortfolioCard, EditInput, TextInputDark, PortfolioCircle, Button, PasswordInputDark, PortfolioGrowthCard } from '../../components'
import portfolioImage from './portfolio.svg';
import checkTick from './checkTick.svg';
import * as d3 from 'd3';

var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
var lengthRegex = new RegExp("(?=.{8,})");
var specialCharacterRegex = new RegExp("(?=.*[!@#\$%\^&\*])");
var upperLowerRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])");
var numberRegex = new RegExp("^(?=.*[0-9])");

const Overview = observer(() => {
    let navigate = useNavigate();
    const store = useStore();
    const svgRef = useRef();
    const [PageLoading, setPageLoading] = useState(true);
    const [SelectedTab, setSelectedTab] = useState('Portfolio');
    const [AllTransactions, setAllTransactions] = useState([]);
    const [TotalPurchaseAmount, setTotalPurchaseAmount] = useState(0);
    const [TotalValue, setTotalValue] = useState(0);
    const [TotalProfit, setTotalProfit] = useState(0);
    const [TotalProfitPercent, setTotalProfitPercent] = useState(0);
    const [RandAmount, setRandAmount] = useState(0);
    const [Portfolio, setPortfolio] = useState([]);
    const [SelectedPortfolio, setSelectedPortfolio] = useState('');
    const [Verified, setVerified] = useState(false);
    const [Email, setEmail] = useState('');
    const [SelectedEdit, setSelectedEdit] = useState('');
    const [Password, setPassword] = useState('');
    const [OldPassword, setOldPassword] = useState('');
    const [ShowPassword, setShowPassword] = useState(false);
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        // const svg = d3.select(svgRef.current);
        // svg.selectAll("*").remove();

        // // Get the dimensions of the SVG element
        // const svgWidth = svg.node().getBoundingClientRect().width;
        // const svgHeight = svg.node().getBoundingClientRect().height;

        // // Calculate the center coordinates
        // const centerX = svgWidth / 2;
        // const centerY = svgHeight / 1.75;

        // const radius = Math.min(200);
        // const cornerRadius = 30;

        // const defs = svg.append("defs");
        // const linearGradient = defs.append("linearGradient")
        //     .attr("id", "gradient")
        //     .attr("x1", "0%")
        //     .attr("y1", "0%")
        //     .attr("x2", "100%")
        //     .attr("y2", "0%");

        // linearGradient.append("stop")
        //     .attr("offset", "0%")
        //     .attr("stop-color", "#2A6091");

        // linearGradient.append("stop")
        //     .attr("offset", "100%")
        //     .attr("stop-color", "#E43180");

        // const arc = d3.arc()
        //     .innerRadius(radius - 15 - cornerRadius)
        //     .outerRadius(radius - cornerRadius - 5)
        //     .cornerRadius(cornerRadius)
        //     .startAngle(-Math.PI / 1.3)
        //     .endAngle(Math.PI / 1.3);

        // svg.append("path")
        //     .attr("d", arc)
        //     .attr("fill", "url(#gradient)")
        //     .attr('transform', `translate(${centerX}, ${centerY})`);

        loadData();
    }, []);

    const loadData = async () => {
        setPageLoading(true);
        store.setLoading(true);
        // return 
        let transactions = await store.getUserTransactions();
        setAllTransactions(transactions);
        let completeTransactions = transactions.filter(trn => trn.Status === 'Complete' && trn.CompanyName !== 'Rand');
        let totalTransactionAmount = completeTransactions.reduce((sum, obj) => sum + obj.Amount, 0);
        let randTransactions = transactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference.includes('Fee_Refund'));
        let totalRandAmount = randTransactions.reduce((sum, obj) => sum + obj.Amount, 0);
        let totalPurchaseAmount = totalTransactionAmount - totalRandAmount;
        setTotalPurchaseAmount(totalPurchaseAmount);
        let totalInterestEarned = 0;
        for (let i = 0; i < completeTransactions.length; i++) {
            let currentTrn = completeTransactions[i];
            let trnRef = currentTrn._id;
            let trnAmount = currentTrn.Amount;
            let trnDate = new Date(currentTrn.Date);
            let currentDate = new Date();
            let feeRefund = transactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference === (`Fee_Refund_${trnRef}`));
            if (feeRefund.length > 0) {
                for (let j = 0; j < feeRefund.length; j++) {
                    trnAmount -= feeRefund[j].Amount;
                }
            }
            let timeElapsedInMilliseconds = currentDate - trnDate;
            let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
            let interestRate = 0.10;
            let trnInterest = trnAmount * (1 + interestRate) ** timeElapsedInYears - trnAmount;
            trnInterest = Number(trnInterest.toFixed(2));
            totalInterestEarned += trnInterest;
        }
        let totalWithInterest = totalPurchaseAmount + totalInterestEarned;
        let portfolio = await store.getPortfolio();
        let totalCoinAmount = 0;
        for (let k = 0; k < portfolio.length; k++) {
            let coinPurchased = Number(portfolio[k].Coin);
            let coinPrice = portfolio[k].Price;
            let coinAmount = coinPurchased * coinPrice;
            totalCoinAmount += coinAmount;
        }
        if (totalCoinAmount > totalWithInterest || (totalCoinAmount === totalWithInterest && totalCoinAmount !== 0)) {
            setTotalValue(Number(totalCoinAmount.toFixed(2)));
            let profit = totalCoinAmount - totalPurchaseAmount;
            setTotalProfit(Number(profit.toFixed(2)));
            let percentageIncrease = ((totalCoinAmount - totalPurchaseAmount) / totalPurchaseAmount) * 100;
            setTotalProfitPercent(Number(percentageIncrease.toFixed(2)));
        } else if (totalCoinAmount < totalWithInterest) {
            setTotalValue(Number(totalWithInterest.toFixed(2)));
            let profit = totalWithInterest - totalPurchaseAmount;
            setTotalProfit(Number(profit.toFixed(2)));
            let percentageIncrease = ((totalWithInterest - totalPurchaseAmount) / totalPurchaseAmount) * 100;
            setTotalProfitPercent(Number(percentageIncrease.toFixed(2)));
        } else {
            setTotalValue(0);
            setTotalProfit(0);
            setTotalProfitPercent(0);
        }
        let user = await store.getUserById(store.user.IDNumber)
        console.log(user)
        setEmail(user.Email || '');
        if (Object.keys(user).includes('RandWallet')) {
            setRandAmount(Number(user.RandWallet.Balance.toFixed(2)))
        }
        let userVerified = await store.checkUserVerified();
        setVerified(userVerified);
        calculatePortfolio(portfolio, completeTransactions, transactions);
    }

    function calculatePortfolio(portfolio, completeTransactions, allTransactions) {
        let portfolioArray = portfolio;
        for (let i = 0; i < portfolioArray.length; i++) {
            let currentPortfolio = portfolioArray[i];
            let companyTransactions = completeTransactions.filter(trn => trn.CompanyName === currentPortfolio.Name);
            let totalPurchaseAmount = 0;
            let totalInterest = 0;
            for (let j = 0; j < companyTransactions.length; j++) {
                let transactionAmount = companyTransactions[j].Amount;
                let trnRef = companyTransactions[j]._id;
                let feeRefund = allTransactions.filter(trn => trn.Status === 'Complete' && trn.TransactionReference === (`Fee_Refund_${trnRef}`));
                if (feeRefund.length > 0) {
                    for (let k = 0; k < feeRefund.length; k++) {
                        transactionAmount -= feeRefund[k].Amount;
                    }
                }
                let trnDate = new Date(companyTransactions[j].Date);
                let currentDate = new Date();
                let timeElapsedInMilliseconds = currentDate - trnDate;
                let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
                let interestRate = 0.10;
                let trnInterest = transactionAmount * (1 + interestRate) ** timeElapsedInYears - transactionAmount;
                totalPurchaseAmount += transactionAmount;
                totalInterest += trnInterest;

            }
            let totalAmountWithInterest = totalPurchaseAmount + totalInterest;
            let currentCoinValue = Number(currentPortfolio.Coin) * currentPortfolio.Price;
            if (currentCoinValue > totalAmountWithInterest || (currentCoinValue === totalAmountWithInterest && currentCoinValue !== 0)) {
                portfolioArray[i].totalValue = Number(currentCoinValue.toFixed(2));
                portfolioArray[i].purchasePrice = Number(totalPurchaseAmount.toFixed(2));
                let prof = currentCoinValue - totalPurchaseAmount;
                portfolioArray[i].profit = Number(prof.toFixed(2));
                let perc = ((currentCoinValue - totalPurchaseAmount) / totalPurchaseAmount) * 100;
                portfolioArray[i].percentProfit = Number(perc.toFixed(2));
            } else if (currentCoinValue < totalAmountWithInterest) {
                portfolioArray[i].totalValue = Number(totalAmountWithInterest.toFixed(2));
                portfolioArray[i].purchasePrice = Number(totalPurchaseAmount.toFixed(2));
                let prof = totalAmountWithInterest - totalPurchaseAmount;
                portfolioArray[i].profit = Number(prof.toFixed(2));
                let perc = ((totalAmountWithInterest - totalPurchaseAmount) / totalPurchaseAmount) * 100;
                portfolioArray[i].percentProfit = Number(perc.toFixed(2));
            } else {
                portfolioArray[i].percentProfit = 0;
                portfolioArray[i].profit = 0;
                portfolioArray[i].purchasePrice = 0;
                portfolioArray[i].totalValue = 0;
            }

        }
        for (let i = 0; i < portfolioArray.length; i++) {
            let currentPortfolio = portfolioArray[i];
            let currentPrice = currentPortfolio.Price;
            let priceHistory = [...currentPortfolio.PriceHistory].sort((a, b) => new Date(a.Date) - new Date(b.Date));
            let initialPrice = priceHistory[0].Price;
            let initialDate = new Date(priceHistory[0].Date);
            let percentageGrowth = ((currentPrice - initialPrice) / initialPrice) * 100;
            const currentDate = new Date();
            const timeElapsedInMilliseconds = currentDate - initialDate;
            let timeElapsedInYears = timeElapsedInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
            let interestRate = 0.10;
            let interest = initialPrice * (1 + interestRate) ** timeElapsedInYears - initialPrice;
            let initialWithInterest = initialPrice + interest;
            const percentageGrowthInterest = ((initialWithInterest - initialPrice) / initialPrice) * 100;
            console.log(percentageGrowthInterest)
            if (percentageGrowthInterest > percentageGrowth || percentageGrowthInterest === percentageGrowth) {
                portfolioArray[i].overallGrowth = percentageGrowthInterest;
            } else {
                portfolioArray[i].overallGrowth = percentageGrowth;
            }
        }
        setPortfolio(portfolioArray);
        setPageLoading(false);
        forceUpdate();
        store.setLoading(false);
    }

    function tabSelected(e) {
        setSelectedTab(e.currentTarget.id);
    }

    function portfolioSelected(e) {
        setSelectedPortfolio(Number(e.currentTarget.id));
    }
    function closePortfolio() {
        setSelectedPortfolio('');
    }

    function inputEditClicked(e) {
        cancelUpdate();
        setSelectedEdit(e.currentTarget.id);
    }

    function emailChanged(e) {
        setEmail(e.target.value);
    }

    const updateEmail = async () => {
        store.setLoading(true);
        let response = await store.updateUserEmail(Email);
        if (response !== true) {
            store.presentPopup(response);
            store.setLoading(false);
            return
        } else {
            store.presentPopup('Email updated successfully.');
            cancelUpdate();
            store.setLoading(false);
        }
    }

    function cancelUpdate() {
        setEmail(store.user.Email);
        setPassword('');
        setOldPassword('');
        setSelectedEdit('');
    }

    function enrollWebauthn() {
        store.setLoading(true);
        if (typeof window.PublicKeyCredential !== 'undefined') {

            window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable().then((available) => {
                if (available) {
                    store.addWebauthn().then((ret) => {
                        if (!ret) {
                            store.presentPopup('There was an error setting up authentication on this device.');
                            store.setLoading(false);
                        } else {
                            store.presentPopup('Authentication for this device has been set up successfully.');
                            store.setLoading(false);
                        }
                    })
                } else {
                    store.presentPopup('Unable to add device authentication');
                    store.setLoading(false);
                }
            })

        } else {
            store.presentPopup('Unable to add device authentication');
            store.setLoading(false);
        }
    }
    function passwordChanged(e) {
        setPassword(e.target.value);
    }
    function oldPasswordChanged(e) {
        setOldPassword(e.target.value);
    }

    function showPasswordClicked() {
        setShowPassword(!ShowPassword);
    }

    const updatePassword = async () => {
        if (OldPassword === '') {
            store.presentPopup('Please enter your old password');
            return
        }
        if (!strongRegex.test(Password)) {
            store.presentPopup('Please enter a valid password');
            return
        }
        let data = {
            Password: Password
        }
        store.setLoading(true);
        let authenticated = await store.loginPassword(store.user.IDNumber, OldPassword);
        if (authenticated !== true) {
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
            return
        }
        let ret = await store.changePassword(data);
        if (!ret) {
            store.presentPopup('An error has occured, please try again.');
            store.setLoading(false);
        } else {
            store.presentPopup('Your password has been changed successfully');
            cancelUpdate();
            store.setLoading(false);
        }
    }

    function goToListings() {
        navigate('/listings');
    }

    function verifyId() {
        navigate('/verification', { state: { source: 'Portfolio' } })
    }

    const formatAmount = (amount) => {
        let amountStr = amount.toString();
        return amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    };
    const handleSwipe = () => {
        setIsVisible(false);
        setTimeout(() => {
            setIsVisible(true);
        }, 300);
    };

    if (isMobile()) {
        return (
            <View style={{ height: `${window.innerHeight - 50}px` }}>
                <div className='overviewTotalsContainer'>
                    {/* <PortfolioCircle width={300} height={350} value={4} /> */}

                    {/* <svg ref={svgRef} style={{ height: "300px", width: '100%', zIndex: '2', position: 'unset', left: '0%' }}/> */}

                    <Carousel className='frstcarousel' showThumbs={false} showArrows={false} showStatus={false} showIndicators={true} infiniteLoop={false} autoPlay={false} axis='horizontal' transitionTime={500} onChange={handleSwipe}>
                        {Array.from({ length: 3 }).map((_, index) => {
                            const color = ['white', '#E43180', '#006CAC'];
                            const title = ['Total Value', 'Total Profit', 'Purchase Value']

                            return (
                                <span key={index} className='totBody' >
                                    <p className='totTitle'>{title[index]}</p>
                                    <p className='totamout' style={{ color: color[index] }}>{`R${index === 0 ? formatAmount(TotalValue.toFixed(2)) : index === 1 ? formatAmount(TotalProfit.toFixed(2)) : formatAmount(TotalPurchaseAmount.toFixed(2))}`}</p>
                                    <p className='userName' style={{ marginLeft: index === 1 ? '7%' : '' }}>{index === 0 ? `${store.user.FirstName || ''}  ${store.user.LastName || ''}` : index === 1 ? `(+${TotalProfitPercent}%)` : ''}</p>
                                </span>
                            );
                        })}
                    </Carousel>
                </div>
                <div className='tabContainer'>
                    <div className={`${(SelectedTab === 'Portfolio' && !PageLoading) ? 'tabItemContainerActive' : 'tabItemContainer'}`} id='Portfolio' onClick={tabSelected}>
                        <p className='tabItemName'>PORTFOLIO</p>
                        <div className='tabItem'>
                            {(SelectedTab !== 'Portfolio') ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 23 23" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.046 18.0921C9.49374 18.0921 9.04602 17.6443 9.04602 17.0921L9.04602 1C9.04602 0.447716 9.49374 0 10.046 0H12.569C13.1213 0 13.569 0.447715 13.569 1V17.0921C13.569 17.6443 13.1213 18.0921 12.569 18.0921H10.046ZM16.9613 17.0921C16.9613 17.6443 17.409 18.0921 17.9613 18.0921H20.4843C21.0366 18.0921 21.4843 17.6443 21.4843 17.0921V4.39226C21.4843 3.83997 21.0366 3.39226 20.4843 3.39226L17.9613 3.39226C17.409 3.39226 16.9613 3.83997 16.9613 4.39226L16.9613 17.0921ZM1.13074 17.0921C1.13074 17.6443 1.57846 18.0921 2.13074 18.0921H4.65375C5.20604 18.0921 5.65375 17.6443 5.65375 17.0921L5.65375 6.65377C5.65375 6.10148 5.20604 5.65377 4.65375 5.65377H2.13074C1.57845 5.65377 1.13074 6.10148 1.13074 6.65377L1.13074 17.0921ZM1 20.3536C0.447715 20.3536 0 20.8013 0 21.3536V21.6151C0 22.1674 0.447716 22.6151 1 22.6151H21.6151C22.1673 22.6151 22.6151 22.1674 22.6151 21.6151V21.3536C22.6151 20.8013 22.1673 20.3536 21.6151 20.3536H1Z" fill="#D1D1D1" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 28 28" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.046 22.0921C11.4937 22.0921 11.046 21.6443 11.046 21.0921L11.046 1C11.046 0.447716 11.4937 0 12.046 0H15.569C16.1213 0 16.569 0.447715 16.569 1L16.569 21.092C16.569 21.6443 16.1213 22.0921 15.569 22.0921H12.046ZM20.7113 21.0921C20.7113 21.6443 21.159 22.0921 21.7113 22.0921H25.2343C25.7866 22.0921 26.2343 21.6443 26.2343 21.092V5.14226C26.2343 4.58997 25.7866 4.14226 25.2343 4.14226H21.7113C21.159 4.14226 20.7113 4.58998 20.7113 5.14226V21.0921ZM1.38075 21.0921C1.38075 21.6443 1.82847 22.0921 2.38075 22.0921H5.90377C6.45605 22.0921 6.90377 21.6443 6.90377 21.0921L6.90377 7.90377C6.90377 7.35148 6.45605 6.90377 5.90377 6.90377H2.38075C1.82847 6.90377 1.38075 7.35148 1.38075 7.90377L1.38075 21.0921ZM1 24.8536C0.447715 24.8536 0 25.3013 0 25.8536V26.6151C0 27.1673 0.447716 27.6151 1 27.6151H26.6151C27.1673 27.6151 27.6151 27.1673 27.6151 26.6151V25.8536C27.6151 25.3013 27.1673 24.8536 26.6151 24.8536H1Z" fill="#E43180" />
                                </svg>
                            }
                        </div>
                    </div>
                    <div className={`${(SelectedTab === 'Transactions' && !PageLoading) ? 'tabItemContainerActive' : 'tabItemContainer'}`} id='Transactions' onClick={tabSelected}>
                        <p className='tabItemName'>TRANSACTIONS</p>
                        <div className='tabItem'>
                            {(SelectedTab !== 'Transactions') ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 22 22" fill="none">
                                    <path d="M0 11.7771C0 11.2619 0 10.7461 0 10.2308C0.0137725 10.1689 0.0321359 10.1069 0.0413175 10.0443C0.103294 9.61344 0.137725 9.1768 0.22782 8.75164C1.52244 2.65877 7.60874 -1.26238 13.8098 0.372295C19.32 1.82451 22.7614 7.15598 21.8558 12.7858C21.449 15.3161 20.264 17.4466 18.3645 19.1679C16.8134 20.5742 14.9994 21.4641 12.9347 21.8296C12.5496 21.8979 12.16 21.9438 11.7726 22H10.2261C9.97933 21.9679 9.73258 21.9398 9.48639 21.9036C7.33214 21.5875 5.41891 20.7348 3.78113 19.297C1.84552 17.5975 0.632388 15.4797 0.171583 12.9402C0.101572 12.5546 0.0562378 12.165 0 11.7771ZM11.7772 7.57885C11.7772 6.64188 11.7847 5.70434 11.7703 4.76738C11.768 4.59869 11.7118 4.41107 11.6234 4.26762C11.4501 3.9859 11.0857 3.87861 10.7713 3.97328C10.4453 4.07139 10.2221 4.3732 10.2221 4.7341C10.2204 6.78762 10.2255 8.84057 10.2169 10.8941C10.2158 11.2418 10.346 11.4908 10.614 11.7008C11.3067 12.2436 11.9901 12.7979 12.677 13.3475C13.2526 13.8077 13.8282 14.2679 14.4032 14.728C14.6597 14.9334 14.946 14.988 15.2467 14.856C15.532 14.7309 15.6869 14.4974 15.7035 14.1841C15.7196 13.8886 15.5836 13.6671 15.3558 13.4858C14.2253 12.5856 13.1 11.6796 11.9677 10.7822C11.8329 10.6749 11.7715 10.5688 11.7732 10.3909C11.783 9.45393 11.7778 8.51639 11.7778 7.57943L11.7772 7.57885Z" fill="#D1D1D1" />
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 35 35" fill="none">
                                    <path d="M0 18.7363C0 17.9166 0 17.096 0 16.2763C0.0219108 16.1777 0.0511253 16.0791 0.0657325 15.9796C0.164331 15.2941 0.219108 14.5995 0.362441 13.9231C2.42206 4.22986 12.1048 -2.00833 21.9702 0.592287C30.7363 2.90263 36.2113 11.3845 34.7706 20.3411C34.1234 24.3666 32.2381 27.7559 29.2163 30.4943C26.7485 32.7317 23.8627 34.1474 20.5779 34.7289C19.9653 34.8375 19.3454 34.9105 18.7292 35H16.2688C15.8762 34.9489 15.4836 34.9042 15.092 34.8466C11.6648 34.3437 8.62099 32.9872 6.01543 30.6997C2.93605 27.996 1.00607 24.6268 0.272972 20.5866C0.161592 19.9732 0.0894692 19.3534 0 18.7363ZM18.7365 12.0573C18.7365 10.5666 18.7484 9.07509 18.7255 7.58446C18.7219 7.31609 18.6324 7.0176 18.4918 6.7894C18.2161 6.34121 17.6364 6.17051 17.1361 6.32113C16.6175 6.47722 16.2624 6.95736 16.2624 7.53152C16.2597 10.7985 16.2679 14.0645 16.2542 17.3315C16.2523 17.8847 16.4596 18.2808 16.8859 18.6149C17.9879 19.4785 19.0752 20.3602 20.168 21.2347C21.0837 21.9668 21.9994 22.6989 22.9142 23.431C23.3222 23.7578 23.7778 23.8445 24.2562 23.6345C24.7099 23.4355 24.9564 23.064 24.9829 22.5656C25.0085 22.0955 24.7921 21.7432 24.4297 21.4547C22.6311 20.0225 20.8408 18.5812 19.0396 17.1535C18.825 16.9828 18.7274 16.814 18.7301 16.531C18.7456 15.0404 18.7374 13.5488 18.7374 12.0582L18.7365 12.0573Z" fill="#E43180" />
                                </svg>
                            }
                        </div>
                    </div>
                    {(!Verified) ?
                        <div className={`${(SelectedTab === 'Verification' && !PageLoading) ? 'tabItemContainerActive' : 'tabItemContainer'}`} id='Verification' onClick={tabSelected}>
                            <p className='tabItemName'>VERIFICATION</p>
                            <div className='tabItem'>
                                {(SelectedTab !== 'Verification') ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                        <path d="M15.7493 23.3854C14.9297 23.6746 14.0813 23.8435 13.2196 23.9324C11.8243 24.0766 10.4371 23.9958 9.08271 23.6325C5.40914 22.6466 2.73035 20.4432 1.09379 17.0041C0.473395 15.7003 0.12576 14.3204 0.0301603 12.8764C-0.189117 9.55017 0.786934 6.61736 2.96968 4.10533C4.91443 1.86782 7.38531 0.529319 10.3161 0.125898C13.725 -0.343646 16.8169 0.500599 19.5104 2.65195C21.877 4.54215 23.2983 7.01477 23.8351 9.99033C23.9515 10.6362 24.0237 11.2901 23.9929 11.95C23.8819 11.936 23.8278 11.8438 23.7589 11.775C22.3878 10.4078 21.0186 9.03855 19.6468 7.67133C19.5746 7.59919 19.5204 7.48632 19.3861 7.50836L19.3981 7.50302C19.3994 7.45159 19.3847 7.41218 19.3272 7.40283C18.749 6.44905 17.6559 6.52719 17.0061 7.19644C14.8427 9.42461 12.6393 11.6134 10.4565 13.8235C10.3034 13.9784 10.2205 14.0032 10.0527 13.8328C9.00115 12.7648 7.93551 11.7102 6.87589 10.6502C6.50219 10.2762 6.04825 10.1493 5.54686 10.2795C4.51799 10.546 4.17838 11.8024 4.92914 12.5591C6.30764 13.9477 7.69685 15.3263 9.08003 16.7102C9.25652 16.8865 9.42967 17.0649 9.64694 17.1931C9.64694 17.3207 9.75324 17.3761 9.82678 17.4496C11.7328 19.3558 13.6401 21.2607 15.5467 23.1663C15.6169 23.2364 15.6818 23.3126 15.7493 23.3854Z" fill="#D1D1D1" />
                                        <path d="M15.7494 23.3853C15.6819 23.3118 15.6171 23.2364 15.5469 23.1662C13.6402 21.2607 11.7329 19.3558 9.82693 17.4496C9.75339 17.3761 9.64643 17.3207 9.6471 17.1931C10.3143 17.4623 10.8685 17.2979 11.3666 16.793C13.8749 14.2482 16.3845 11.7062 18.9096 9.17811C19.3956 8.69186 19.5821 8.16755 19.3862 7.50965C19.5206 7.48761 19.5747 7.59982 19.6469 7.67263C21.0188 9.03985 22.3879 10.4091 23.7591 11.7763C23.8279 11.8451 23.8821 11.9379 23.9931 11.9513C24.0372 13.4681 23.7597 14.9322 23.1948 16.3328C21.8063 19.7766 19.3468 22.119 15.8457 23.368C15.8149 23.3786 15.7815 23.3806 15.7494 23.3867V23.3853Z" fill="#9A9A9A" />
                                        <path d="M19.3983 7.50375C19.3468 7.48973 19.3474 7.43896 19.3274 7.40356C19.3849 7.41292 19.4003 7.45232 19.3983 7.50375Z" fill="#9A9A9A" />
                                    </svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 39 39" fill="none">
                                        <path d="M25.5926 38.0013C24.2607 38.4712 22.8822 38.7458 21.4818 38.8902C19.2146 39.1246 16.9604 38.9933 14.7594 38.4029C8.78987 36.8009 4.43682 33.2203 1.77741 27.6317C0.769268 25.5131 0.20436 23.2707 0.0490106 20.9242C-0.307316 15.5191 1.27877 10.7532 4.82574 6.67118C7.98597 3.03521 12.0012 0.860145 16.7638 0.204585C22.3031 -0.558425 27.3275 0.813474 31.7045 4.30943C35.5502 7.38101 37.8598 11.399 38.7322 16.2343C38.9212 17.2839 39.0385 18.3464 38.9885 19.4188C38.8082 19.396 38.7202 19.2462 38.6083 19.1344C36.3802 16.9127 34.1553 14.6877 31.9261 12.4659C31.8088 12.3487 31.7208 12.1653 31.5024 12.2011L31.522 12.1924C31.5242 12.1089 31.5003 12.0448 31.4068 12.0296C30.4671 10.4797 28.6909 10.6067 27.635 11.6942C24.1195 15.315 20.5389 18.8718 16.9919 22.4632C16.7431 22.715 16.6084 22.7552 16.3357 22.4784C14.6269 20.7429 12.8952 19.0291 11.1734 17.3067C10.5661 16.6989 9.82844 16.4926 9.01366 16.7043C7.34175 17.1373 6.78988 19.1789 8.00987 20.4086C10.2499 22.6651 12.5074 24.9053 14.7551 27.1542C15.0419 27.4407 15.3233 27.7305 15.6763 27.9389C15.6763 28.1462 15.8491 28.2363 15.9685 28.3557C19.0658 31.4533 22.1652 34.5487 25.2635 37.6453C25.3775 37.7593 25.4829 37.883 25.5926 38.0013Z" fill="#E43180" />
                                        <path d="M25.5925 38.0013C25.4827 37.8819 25.3774 37.7593 25.2633 37.6453C22.165 34.5488 19.0656 31.4533 15.9684 28.3557C15.8489 28.2363 15.6751 28.1462 15.6762 27.9389C16.7603 28.3763 17.6609 28.1093 18.4703 27.2888C22.5463 23.1535 26.6245 19.0227 30.7277 14.9145C31.5175 14.1244 31.8206 13.2724 31.5023 12.2033C31.7206 12.1675 31.8086 12.3498 31.9259 12.4681C34.1552 14.6899 36.38 16.9149 38.6082 19.1366C38.72 19.2484 38.808 19.3993 38.9884 19.421C39.0601 21.8858 38.6092 24.265 37.6913 26.541C35.4349 32.1371 31.4382 35.9435 25.7489 37.9731C25.6989 37.9905 25.6446 37.9937 25.5925 38.0035V38.0013Z" fill="#B92969" />
                                        <path d="M31.522 12.1936C31.4384 12.1708 31.4395 12.0883 31.4069 12.0308C31.5003 12.046 31.5253 12.11 31.522 12.1936Z" fill="#B92969" />
                                    </svg>
                                }
                            </div>
                        </div>
                        : null}
                    <div className={`${(SelectedTab === 'Profile' && !PageLoading) ? 'tabItemContainerActive' : 'tabItemContainer'}`} id='Profile' onClick={tabSelected}>
                        <p className='tabItemName'>PROFILE</p>
                        <div className='tabItem'>
                            {(SelectedTab !== 'Profile') ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none">
                                    <g clip-path="url(#clip0_938_3774)">
                                        <path d="M12.7985 23.9997H11.2052C11.1382 23.9841 11.0712 23.9662 11.0035 23.9545C10.2753 23.8267 9.53162 23.7589 8.82216 23.5649C3.08967 21.9973 -0.716158 16.429 0.114006 10.3386C0.431743 8.00668 1.35146 5.92097 2.91601 4.1625C5.78108 0.941619 9.38521 -0.407816 13.6591 0.105624C15.6379 0.343255 17.4408 1.08887 19.045 2.27703C21.717 4.25599 23.329 6.89098 23.8617 10.1789C23.9162 10.5162 23.9544 10.8567 23.9995 11.1964V12.7912C23.9847 12.8512 23.9614 12.9105 23.9567 12.9712C23.8562 14.362 23.5268 15.6974 22.9248 16.9525C21.0683 20.8232 18.0225 23.1348 13.7884 23.8641C13.4605 23.921 13.128 23.9553 12.7978 23.9997H12.7985ZM12.0252 13.4831C12.0252 13.4831 12.0252 13.4815 12.0252 13.48C11.1437 13.48 10.259 13.4364 9.38054 13.4886C7.32382 13.6109 5.71956 15.3125 5.67751 17.3709C5.66583 17.9327 5.87376 18.4103 6.30987 18.771C7.80276 20.0059 9.50826 20.7118 11.4497 20.8263C13.684 20.958 15.6737 20.3191 17.454 18.9673C18.116 18.4648 18.3846 17.8509 18.2997 17.0234C18.1089 15.1606 16.5413 13.5922 14.6707 13.4917C13.7915 13.4442 12.9076 13.4839 12.026 13.4839L12.0252 13.4831ZM15.9642 7.54933C15.9666 5.36312 14.1995 3.59452 12.0081 3.58985C9.81587 3.58517 8.06053 5.33274 8.04262 7.53687C8.02471 9.71684 9.82911 11.5291 12.0081 11.5197C14.191 11.5096 15.9619 9.73243 15.9642 7.54933Z" fill="#D1D1D1" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_938_3774">
                                            <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                :
                                <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 36 36" fill="none">
                                    <path d="M19.1976 35.9997H16.8075C16.7071 35.9764 16.6066 35.9495 16.505 35.932C15.4128 35.7403 14.2972 35.6386 13.233 35.3476C4.63426 32.9962 -1.07448 24.6437 0.170765 15.5081C0.64737 12.0103 2.02695 8.8817 4.37376 6.24399C8.67138 1.41267 14.0776 -0.61148 20.4884 0.15868C23.4566 0.515127 26.1609 1.63355 28.5673 3.41579C32.5752 6.38423 34.9933 10.3367 35.7923 15.2685C35.8741 15.7746 35.9313 16.2853 35.9991 16.7948V19.1871C35.9769 19.2771 35.9418 19.3659 35.9348 19.4571C35.7841 21.5432 35.29 23.5463 34.387 25.429C31.6021 31.235 27.0335 34.7025 20.6823 35.7964C20.1905 35.8817 19.6917 35.9331 19.1964 35.9997H19.1976ZM18.0376 20.2249C18.0376 20.2249 18.0376 20.2226 18.0376 20.2202C16.7153 20.2202 15.3882 20.1548 14.0706 20.2331C10.9855 20.4166 8.5791 22.969 8.51602 26.0566C8.4985 26.8992 8.81039 27.6156 9.46455 28.1567C11.7039 30.0091 14.2621 31.0679 17.1743 31.2397C20.5258 31.4372 23.5104 30.4789 26.1808 28.4512C27.1737 27.6974 27.5767 26.7765 27.4494 25.5354C27.1632 22.7411 24.8117 20.3885 22.0058 20.2378C20.687 20.1665 19.3611 20.2261 18.0388 20.2261L18.0376 20.2249ZM23.9461 11.3242C23.9496 8.04493 21.2991 5.39203 18.0119 5.38501C14.7236 5.378 12.0906 7.99935 12.0637 11.3055C12.0368 14.5755 14.7434 17.2939 18.0119 17.2798C21.2862 17.2646 23.9426 14.5989 23.9461 11.3242Z" fill="#E43180" />
                                </svg>
                            }
                        </div>
                    </div>
                </div>
                {(Portfolio.length > 0) ?
                    <div className='tabContent'>
                        {(SelectedTab === 'Portfolio') ?
                            <>
                                {(RandAmount > 0) ?
                                    <div className='portfolioRandContainer'>
                                        <p className='portfolioRandHeader'>Your Funds to Invest</p>
                                        <p className='portfolioRandAmount'>R{RandAmount.toFixed(2)}</p>
                                    </div>
                                    : null}
                                {(TotalValue > 0) ?
                                    <>
                                        <p style={{ color: '#0B132F', margin: '0px', fontSize: '13px', fontWeight: '700', textAlign: 'left', marginTop: '20px', marginBottom: '7px' }}>Current Investments:</p>
                                        {Portfolio.map((portfolio, index) => {
                                            if (portfolio.totalValue === 0) {
                                                return
                                            }
                                            return (
                                                <PortfolioCard portfolio={portfolio} id={index} selected={SelectedPortfolio === index} openPortfolio={portfolioSelected} closePortfolio={closePortfolio} investFurther={goToListings} />
                                            )
                                        })}
                                        <div style={{ height: '3px' }}></div>
                                        <hr style={{ width: '100%', height: '2px', color: '#00000040' }} />
                                        <p style={{ color: '#0B132F', margin: '0px', fontSize: '13px', fontWeight: '700', textAlign: 'left', marginTop: '20px', marginBottom: '7px' }}>Other Investments:</p>
                                        {Portfolio.map((portfolio, index) => {
                                            if (portfolio.totalValue !== 0) {
                                                return
                                            }
                                            return (
                                                <PortfolioGrowthCard portfolio={portfolio} id={index} selected={SelectedPortfolio === index} openPortfolio={goToListings} closePortfolio={closePortfolio} />
                                            )
                                        })}
                                    </>
                                    :
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '250px' }}>
                                            <p className='noInvestmentsP'>You currently have no investments. Make your first investment now to start your portfolio.</p>
                                            <Button enabled={true} onClick={goToListings} style={{ marginTop: '25%' }}>Start Your Portfolio</Button>
                                        </div>
                                    </>
                                }
                            </>
                            : null}
                        {(SelectedTab === 'Transactions') ?
                            <>
                                <table className='transactionsTable'>
                                    <thead>
                                        <tr>
                                            <th className='dateColumn'>Date</th>
                                            <th className='companyColumn'>Company</th>
                                            <th className='amountColumn' style={{ textAlign: 'center' }}>Amount</th>
                                            <th className='statusColumn'></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {AllTransactions.map((transaction) => {
                                            let date = new Date(transaction.Date);
                                            console.log(date.toLocaleDateString());
                                            return (
                                                <tr>
                                                    <td className='dateColumn'>{date.toLocaleDateString()}</td>
                                                    <td className='companyColumn'>{transaction.CompanyName}</td>
                                                    <td className='amountColumn' style={{ textAlign: 'center' }}>R{transaction.Amount.toFixed(2)}</td>
                                                    <td className='statusColumn'>
                                                        {(transaction.Status === 'Complete') ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <path d="M11.2456 16.698C10.6603 16.9045 10.0546 17.0252 9.43925 17.0886C8.44301 17.1916 7.4525 17.1339 6.48539 16.8745C3.86233 16.1705 1.94957 14.5972 0.781006 12.1416C0.338021 11.2106 0.0897972 10.2253 0.0215355 9.19421C-0.135037 6.81917 0.5619 4.72504 2.12046 2.93136C3.50908 1.33369 5.27338 0.377953 7.3661 0.089896C9.80013 -0.245376 12.0079 0.357446 13.9312 1.89359C15.621 3.24326 16.6358 5.0088 17.0192 7.13346C17.1022 7.59464 17.1538 8.06154 17.1318 8.53273C17.0526 8.52272 17.0139 8.4569 16.9647 8.40778C15.9857 7.43153 15.0081 6.45386 14.0285 5.47761C13.977 5.4261 13.9383 5.3455 13.8424 5.36124L13.851 5.35743C13.8519 5.32071 13.8414 5.29257 13.8004 5.28589C13.3874 4.60486 12.607 4.66065 12.143 5.13852C10.5983 6.72951 9.02491 8.29237 7.46635 9.87048C7.35703 9.98112 7.29784 9.99877 7.17803 9.87716C6.42715 9.11457 5.66625 8.36152 4.90964 7.60465C4.6428 7.33758 4.31868 7.24697 3.96066 7.33997C3.22601 7.53025 2.98352 8.42733 3.51959 8.96768C4.50389 9.95919 5.49583 10.9435 6.48348 11.9317C6.6095 12.0576 6.73313 12.185 6.88827 12.2765C6.88827 12.3676 6.96417 12.4072 7.01668 12.4597C8.37762 13.8208 9.73951 15.1809 11.1009 16.5416C11.151 16.5916 11.1973 16.646 11.2456 16.698Z" fill="#6FDB45" />
                                                                <path d="M11.2456 16.6979C11.1974 16.6455 11.1511 16.5916 11.101 16.5415C9.73954 15.1809 8.37765 13.8207 7.01672 12.4596C6.96421 12.4071 6.88783 12.3675 6.88831 12.2764C7.36471 12.4686 7.76043 12.3513 8.11606 11.9908C9.90709 10.1737 11.6991 8.35858 13.502 6.55346C13.8491 6.20626 13.9823 5.83188 13.8424 5.36212C13.9383 5.34638 13.977 5.4265 14.0286 5.47849C15.0081 6.45473 15.9857 7.43241 16.9648 8.40866C17.0139 8.45778 17.0526 8.52407 17.1318 8.53361C17.1634 9.61668 16.9652 10.6621 16.5619 11.6622C15.5704 14.1212 13.8142 15.7937 11.3143 16.6855C11.2924 16.6932 11.2685 16.6946 11.2456 16.6989V16.6979Z" fill="#6FDB45" />
                                                                <path d="M13.8517 5.35888C13.8144 5.34873 13.8149 5.312 13.8004 5.28638C13.842 5.29314 13.8531 5.32166 13.8517 5.35888Z" fill="#6FDB45" />
                                                            </svg>
                                                            : null}
                                                        {(transaction.Status === 'Pending') ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <path d="M0 9.10051C0 8.70236 0 8.30378 0 7.90563C0.0106424 7.85775 0.0248323 7.80987 0.0319272 7.76154C0.079818 7.42857 0.106424 7.09117 0.176043 6.76263C1.17643 2.0545 5.87948 -0.975473 10.6712 0.287682C14.9291 1.40985 17.5883 5.52962 16.8886 9.87995C16.5742 11.8352 15.6585 13.4814 14.1908 14.8115C12.9922 15.8982 11.5905 16.5859 9.99499 16.8683C9.69744 16.9211 9.39635 16.9565 9.09703 17H7.90198C7.7113 16.9752 7.52063 16.9534 7.3304 16.9255C5.66575 16.6812 4.18734 16.0224 2.92178 14.9113C1.42608 13.598 0.488663 11.9616 0.132587 9.99922C0.0784877 9.70127 0.0434565 9.40023 0 9.10051ZM9.10058 5.85639C9.10058 5.13237 9.10635 4.4079 9.09526 3.68388C9.09349 3.55353 9.05003 3.40855 8.98174 3.29771C8.84782 3.08001 8.56624 2.9971 8.32324 3.07026C8.07137 3.14608 7.89888 3.37929 7.89888 3.65817C7.89755 5.24498 7.90154 6.83135 7.89489 8.41817C7.894 8.68685 7.99466 8.87927 8.20174 9.04154C8.73697 9.46097 9.26509 9.88926 9.79588 10.314C10.2406 10.6696 10.6854 11.0252 11.1297 11.3808C11.3279 11.5395 11.5492 11.5816 11.7816 11.4796C12.002 11.383 12.1217 11.2025 12.1346 10.9604C12.147 10.7321 12.0419 10.561 11.8658 10.4209C10.9923 9.72522 10.1227 9.02514 9.2478 8.33171C9.14359 8.2488 9.09615 8.16678 9.09748 8.02933C9.10502 7.30531 9.10102 6.58085 9.10102 5.85683L9.10058 5.85639Z" fill="black" fill-opacity="0.25" />
                                                            </svg>
                                                            : null}
                                                        {(transaction.Status === 'Failed') ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                                                <path d="M16.9999 8.51613C17.0214 13.0236 13.3812 16.993 8.48572 17C3.78142 17.0063 0.00824265 13.1792 1.39347e-05 8.48325C-0.00821478 3.97197 3.62888 0.00886507 8.518 1.32151e-05C13.2204 -0.00820636 17.0081 3.81895 16.9999 8.51613ZM9.86308 8.50538C9.93714 8.42761 9.99031 8.3707 10.0447 8.31569C10.6416 7.71883 11.2379 7.12133 11.8367 6.52572C12.0728 6.29052 12.2121 6.01421 12.1665 5.67784C12.1127 5.27761 11.8911 4.99182 11.5044 4.86094C11.1157 4.7288 10.7727 4.83376 10.4859 5.11828C9.8751 5.724 9.26871 6.33288 8.65979 6.9405C8.60535 6.99487 8.54775 7.04672 8.48192 7.10868C8.42052 7.05304 8.36672 7.00878 8.31798 6.9601C7.70905 6.35311 7.10013 5.74613 6.49247 5.13725C6.25637 4.90014 5.97723 4.76926 5.64111 4.81795C5.24993 4.87422 4.97142 5.09299 4.83976 5.46793C4.70304 5.85488 4.80179 6.2001 5.08599 6.48779C5.69112 7.0992 6.30194 7.70429 6.9096 8.31253C6.9691 8.37197 7.0248 8.43519 7.12671 8.54395C7.04506 8.59895 6.96974 8.63436 6.91467 8.68874C6.30954 9.28877 5.70884 9.89259 5.10498 10.4939C4.86762 10.7304 4.73912 11.0105 4.79166 11.3462C4.85242 11.7363 5.07713 12.0113 5.45122 12.1416C5.83924 12.2769 6.18231 12.1713 6.46968 11.8874C7.08051 11.2823 7.68753 10.6728 8.29646 10.0658C8.35532 10.007 8.41862 9.95202 8.51547 9.86161C8.57687 9.94444 8.61738 10.0153 8.67245 10.0709C9.27947 10.6804 9.88523 11.2905 10.498 11.8937C10.7853 12.177 11.1309 12.2725 11.5189 12.1353C11.8956 12.0019 12.1102 11.7224 12.1659 11.3323C12.2159 10.9852 12.069 10.7057 11.8266 10.4648C11.1778 9.82051 10.5315 9.17306 9.86308 8.50664V8.50538Z" fill="#FF0000" fill-opacity="0.25" />
                                                            </svg>
                                                            : null}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <br /><br />
                            </>
                            : null}
                        {(SelectedTab === 'Verification') ?
                            <>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <p className='verificationCopy'>Before you can invest, you need to verify your identity. This should less than 3 minutes.</p>
                                    <Button onClick={verifyId} enabled={true} style={{ marginTop: '55px', marginBottom: '10px' }}>Verify My Identity</Button>
                                </div>
                            </>
                            : null}
                        {(SelectedTab === 'Profile') ?
                            <>
                                <div className='profileHeaderContainer'>
                                    <div className='profileInfo'>
                                        <p style={{ marginBottom: '2px' }}>{store.user.FirstName || ''} {store.user.LastName || ''}</p>
                                        <p style={{ marginTop: '2px' }}>{store.user.IDNumber || ''}</p>
                                    </div>
                                    <div className='verifiedInfo'>
                                        {(Verified) ?
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                                                    <path d="M28.2182 41.8999C26.7497 42.418 25.2297 42.7208 23.6857 42.88C21.1858 43.1385 18.7004 42.9937 16.2736 42.3427C9.69163 40.5763 4.892 36.6283 1.95976 30.4665C0.848188 28.1305 0.225326 25.6581 0.0540386 23.0708C-0.338844 17.1112 1.40996 11.8564 5.32082 7.35558C8.80525 3.3466 13.2324 0.948388 18.4836 0.225574C24.5912 -0.615715 30.1311 0.896929 34.9571 4.75154C39.1973 8.13823 41.7439 12.5685 42.7057 17.8998C42.9141 19.057 43.0435 20.2286 42.9884 21.411C42.7896 21.3858 42.6925 21.2207 42.5692 21.0974C40.1125 18.6478 37.6593 16.1945 35.2014 13.7448C35.0721 13.6156 34.975 13.4133 34.7343 13.4528L34.7558 13.4433C34.7582 13.3511 34.7319 13.2805 34.6289 13.2637C33.5928 11.5548 31.6343 11.6949 30.4701 12.894C26.594 16.8862 22.646 20.8078 18.7351 24.7677C18.4608 25.0454 18.3123 25.0897 18.0116 24.7845C16.1275 22.871 14.2182 20.9813 12.3196 19.0822C11.6501 18.412 10.8367 18.1846 9.93838 18.418C8.09495 18.8955 7.48646 21.1465 8.8316 22.5024C11.3015 24.9903 13.7905 27.4603 16.2688 29.9399C16.585 30.2559 16.8953 30.5754 17.2846 30.8052C17.2846 31.0337 17.475 31.133 17.6068 31.2647C21.0217 34.6801 24.4391 38.0931 27.8553 41.5073C27.981 41.633 28.0972 41.7694 28.2182 41.8999Z" fill="#E43180" />
                                                    <path d="M28.2181 41.8999C28.0971 41.7682 27.9809 41.633 27.8551 41.5074C24.439 38.0931 21.0216 34.6801 17.6066 31.2647C17.4749 31.1331 17.2832 31.0337 17.2844 30.8052C18.4798 31.2874 19.4728 30.9931 20.3652 30.0883C24.8594 25.5289 29.356 20.9742 33.8801 16.4446C34.7509 15.5734 35.0851 14.634 34.7342 13.4552C34.9749 13.4158 35.0719 13.6168 35.2013 13.7472C37.6592 16.1969 40.1123 18.6502 42.569 21.0998C42.6924 21.2231 42.7894 21.3894 42.9883 21.4134C43.0673 24.1311 42.5702 26.7543 41.5581 29.2638C39.0702 35.4341 34.6635 39.6309 28.3905 41.8688C28.3354 41.8879 28.2756 41.8915 28.2181 41.9023V41.8999Z" fill="#B92969" />
                                                    <path d="M34.7559 13.4444C34.6636 13.4193 34.6648 13.3283 34.6289 13.2649C34.7319 13.2816 34.7595 13.3523 34.7559 13.4444Z" fill="#B92969" />
                                                </svg>
                                                <p>Verified</p>
                                            </>
                                            :
                                            <>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43" fill="none">
                                                    <path d="M28.2182 41.8999C26.7497 42.418 25.2297 42.7208 23.6857 42.88C21.1858 43.1385 18.7004 42.9937 16.2736 42.3427C9.69163 40.5763 4.892 36.6283 1.95976 30.4665C0.848188 28.1305 0.225326 25.6581 0.0540386 23.0708C-0.338844 17.1112 1.40996 11.8564 5.32082 7.35558C8.80525 3.3466 13.2324 0.948388 18.4836 0.225574C24.5912 -0.615715 30.1311 0.896929 34.9571 4.75154C39.1973 8.13823 41.7439 12.5685 42.7057 17.8998C42.9141 19.057 43.0435 20.2286 42.9884 21.411C42.7896 21.3858 42.6925 21.2207 42.5692 21.0974C40.1125 18.6478 37.6593 16.1945 35.2014 13.7448C35.0721 13.6156 34.975 13.4133 34.7343 13.4528L34.7558 13.4433C34.7582 13.3511 34.7319 13.2805 34.6289 13.2637C33.5928 11.5548 31.6343 11.6949 30.4701 12.894C26.594 16.8862 22.646 20.8078 18.7351 24.7677C18.4608 25.0454 18.3123 25.0897 18.0116 24.7845C16.1275 22.871 14.2182 20.9813 12.3196 19.0822C11.6501 18.412 10.8367 18.1846 9.93838 18.418C8.09495 18.8955 7.48646 21.1465 8.8316 22.5024C11.3015 24.9903 13.7905 27.4603 16.2688 29.9399C16.585 30.2559 16.8953 30.5754 17.2846 30.8052C17.2846 31.0337 17.475 31.133 17.6068 31.2647C21.0217 34.6801 24.4391 38.0931 27.8553 41.5073C27.981 41.633 28.0972 41.7694 28.2182 41.8999Z" fill="#E43180" />
                                                    <path d="M28.2181 41.8999C28.0971 41.7682 27.9809 41.633 27.8551 41.5074C24.439 38.0931 21.0216 34.6801 17.6066 31.2647C17.4749 31.1331 17.2832 31.0337 17.2844 30.8052C18.4798 31.2874 19.4728 30.9931 20.3652 30.0883C24.8594 25.5289 29.356 20.9742 33.8801 16.4446C34.7509 15.5734 35.0851 14.634 34.7342 13.4552C34.9749 13.4158 35.0719 13.6168 35.2013 13.7472C37.6592 16.1969 40.1123 18.6502 42.569 21.0998C42.6924 21.2231 42.7894 21.3894 42.9883 21.4134C43.0673 24.1311 42.5702 26.7543 41.5581 29.2638C39.0702 35.4341 34.6635 39.6309 28.3905 41.8688C28.3354 41.8879 28.2756 41.8915 28.2181 41.9023V41.8999Z" fill="#B92969" />
                                                    <path d="M34.7559 13.4444C34.6636 13.4193 34.6648 13.3283 34.6289 13.2649C34.7319 13.2816 34.7595 13.3523 34.7559 13.4444Z" fill="#B92969" />
                                                </svg>
                                                <p>Not Verified</p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <hr style={{ color: '#00000040', width: '100%', height: '0.5px', marginTop: '20px' }} />
                                <div className='profileInputs'>
                                    {(SelectedEdit === 'Email') ? <TextInputDark placeholder={'Email'} id='Email' onChange={emailChanged} value={Email} /> : <EditInput placeholder={'Email'} id='Email' onClick={inputEditClicked} value={Email} />}
                                    {(SelectedEdit === 'Password') ? <PasswordInputDark value={OldPassword} onChange={oldPasswordChanged} placeholder='Old Password' showPassword={ShowPassword} onShowPassword={showPasswordClicked} /> : <EditInput placeholder={'Password'} id='Password' onClick={inputEditClicked} value='************' />}
                                    <PasswordInputDark className={`slidingPasswordInput ${(SelectedEdit !== 'Password') ? 'slidingPasswordInputHidden' : ''}`} value={Password} onChange={passwordChanged} placeholder='New Password' showPassword={ShowPassword} onShowPassword={showPasswordClicked} />
                                    {(SelectedEdit === 'Email') ?
                                        <>
                                            <br /><br />
                                            <div className='profileUpdatesButtonContainer'>
                                                <Button onClick={cancelUpdate} enabled={true} style={{ backgroundColor: '#0000001A', color: '#0B132F', width: '45%', minWidth: '45%' }}>Cancel</Button>
                                                <Button onClick={updateEmail} enabled={(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Email))} style={{ minWidth: '45%', width: '45%' }} disabledColor='#0000001A'>Update</Button>
                                            </div>
                                            <br />
                                        </>
                                        : null}
                                    {(SelectedEdit === 'Password') ?
                                        <>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px' }}>
                                                <img src={checkTick} style={{ borderRadius: '50%', backgroundColor: `${(lengthRegex.test(Password)) ? '#D42E79' : '#0000001A'}`, width: '15px', height: '15px' }} />
                                                <p style={{ fontSize: '14px', color: `${(lengthRegex.test(Password)) ? '#0B132F' : '#0B132FCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px' }}>Minimum 8 Characters</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px' }}>
                                                <img src={checkTick} style={{ borderRadius: '50%', backgroundColor: `${(specialCharacterRegex.test(Password)) ? '#D42E79' : '#0000001A'}`, width: '15px', height: '15px' }} />
                                                <p style={{ fontSize: '14px', color: `${(specialCharacterRegex.test(Password)) ? '#0B132F' : '#0B132FCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px' }}>One special character</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px' }}>
                                                <img src={checkTick} style={{ borderRadius: '50%', backgroundColor: `${(upperLowerRegex.test(Password)) ? '#D42E79' : '#0000001A'}`, width: '15px', height: '15px' }} />
                                                <p style={{ fontSize: '14px', color: `${(upperLowerRegex.test(Password)) ? '#0B132F' : '#0B132FCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px' }}>One uppercase and lowercase character</p>
                                            </div>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContend: 'flex-start', marginLeft: '15%', alignItems: 'center', marginBottom: '4px' }}>
                                                <img src={checkTick} style={{ borderRadius: '50%', backgroundColor: `${(numberRegex.test(Password)) ? '#D42E79' : '#0000001A'}`, width: '15px', height: '15px' }} />
                                                <p style={{ fontSize: '14px', color: `${(numberRegex.test(Password)) ? '#0B132F' : '#0B132FCC'}`, fontWeight: '300', margin: '0px', marginLeft: '11px' }}>One number</p>
                                            </div>
                                            <br /><br />
                                            <div className='profileUpdatesButtonContainer'>
                                                <Button onClick={cancelUpdate} enabled={true} style={{ backgroundColor: '#0000001A', color: '#0B132F', width: '45%', minWidth: '45%' }}>Cancel</Button>
                                                <Button onClick={updatePassword} enabled={(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(Password)) && OldPassword !== ''} style={{ minWidth: '45%', width: '45%' }} disabledColor='#0000001A'>Update</Button>
                                            </div>
                                            <br />
                                        </>
                                        : null}
                                    {(SelectedEdit === '') ?
                                        <>
                                            <br /><br />
                                            <div className='profileUpdatesButtonContainer'>
                                                <Button onClick={enrollWebauthn} enabled={true}>Add biometrics</Button>
                                            </div>
                                            <br />
                                        </>
                                        : null}


                                </div>

                            </>
                            : null}
                    </div>
                    : null}
                {(TotalValue === 0) ? <img className='portfolioLandscape' src='https://wealthcdn.aions.co.za/landscapes/PortfolioBarren.png' /> : <img className='portfolioLandscape' src='https://wealthcdn.aions.co.za/landscapes/PortfolioHappy.png' />}
                <Footer />
            </View>

        );
    } else {
        return (
            <View className='desktopView' style={{ overflow: 'scroll', height: `${window.innerHeight - 75}px` }}>

            </View>

        );
    }

})

export default Overview