import React, { useState, useEffect } from 'react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { Filter } from '../../../components';
import filter from './images/filter.svg';
import Transactions from './Transactions';
import SignUps from './Signups';
import FailedTransactions from './FailedTransactions';
import Conversions from './Conversions';
import ReportingPDF from './ReportingPDF/ReportingPDF';
import AEXLogo from './AEXLogo.png';
import { svgAsPngUri } from 'save-svg-as-png';

const categories = ['signUps', 'transactions', 'failedTransactions', 'conversions'];
const lineIDs = ['signupLineID', 'TransLineID', 'failedTransLine'];
const pieIDs = ['signupPieChart', 'transPieID', 'failedTransPie_Id'];
const barIDs = ['signupBar', 'transBarID', 'failedTransBar'];

const Reporting = () => {
    const store = useStore();
    const [, updateState] = useState();
    const [TransArray, setTransArray] = useState([]);
    const [TransArrayCopy, setTransArrayCopy] = useState([]);
    const [ToggleFilter, setToggleFilter] = useState(false);
    const [Users, setUsers] = useState([]);
    const [CompanyNames, setCompanyNames] = useState([]);
    const [Userscopy, setUserscopy] = useState([]);
    const [CompanyTrans, setCompanyTrans] = useState([]);
    const [IsDefaul, setIsDefault] = useState(1);
    const [ReportGenerated, setReportGenerated] = useState(false);
    const [Dates, setDates] = useState({
        StartDate: new Date().toLocaleDateString(),
        EndDate: new Date().toLocaleDateString()
    });
    const [FilterData, setFilterData] = useState({
        StartDate: '00/00/0000',
        EndDate: '00/00/0000',
        Selectedbtn: ''
    });
    const [pdfUrl, setPdfUrl] = useState(null);
    const initializeCategory = () => ({
        Line: null,
        Pie: {
            Image: null,
            Text: {
                Pink: '',
                White: ''
            }
        },
        Bar: null
    });
    const [ImageObj, setImageObj] = useState({
        signUps: initializeCategory(),
        transactions: initializeCategory(),
        failedTransactions: initializeCategory(),
        conversions: initializeCategory(),
        compTrans: {}
    });

    useEffect(() => {
        firstProcess();
    }, []);
    
    const firstProcess = async () => {
        store.setLoading(true);
        try {
            const promises = [
                getDefaultDates(),
                getCompanies(),
                getUsers(),
                getTransactions(),
            ];
            
            await Promise.all(promises);
    
            setIsDefault(1);
        } catch (error) {
            console.error("Error in firstProcess:", error);
        } finally {
            store.setLoading(false);
        }
    };
    

    const handlePdfGenerated = (url) => {
        setPdfUrl(url);
        setReportGenerated(false)
        store.setLoading(false);
    };

    const getDefaultDates = async () => {
        try {
            let dates = { ...Dates };
            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const startDate = firstDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
            const endDate = lastDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');

            dates.StartDate = startDate.replace(/-/g, "/");
            dates.EndDate = endDate.replace(/-/g, "/");

            setDates(dates);
            return dates;
        } catch (error) {
            console.error("Error in getDefaultDates:", error);
        }
    };

    const getTransactions = async () => {
        try {
            const trans = await store.getTransactions();

            if (trans.length > 0) {
                const today = new Date();
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                const startDate = firstDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
                const endDate = lastDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
                const strtNum = Number(`${startDate.slice(6, 10)}${startDate.slice(3, 5)}${startDate.slice(0, 2)}`);
                const endNum = Number(`${endDate.slice(6, 10)}${endDate.slice(3, 5)}${endDate.slice(0, 2)}`);
                const inRangeTrans = trans.filter((item) => {
                    const itemDateNum = Number(`${item.Date.slice(0, 4)}${item.Date.slice(5, 7)}${item.Date.slice(8, 10)}`);
                    return itemDateNum >= strtNum && itemDateNum <= endNum;
                });

                setTransArrayCopy(inRangeTrans);
                setTransArray(trans);
            } else {
                setTransArray([]);
                setTransArrayCopy([]);
            }
        } catch (error) {
            console.error("Error in getTransactions:", error);
        }
    };

    const getCompanies = async () => {
        try {
            const companies = await store.getCompanies();
            setCompanyNames(companies.map((company) => company.Name).sort((a, b) => a - b));
        } catch (error) {
            console.error("Error in getCompanies:", error);
        }
    };

    const toggleFilter = () => {
        setToggleFilter(!ToggleFilter);
        setReportGenerated(false);
        setPdfUrl(null);
    };

    const handleSubmit = (e) => {
        let transactions = e;
        setTransArrayCopy(transactions);
    };

    const getFilterData = (e) => {
        setFilterData(e);
        setDates({
            StartDate: e.StartDate,
            EndDate: e.EndDate,
            Selectedbtn: e.selectedbtn
        });
    };

    const handleOnclear = () => {
        toggleFilter();
        setFilterData(Dates);
        setTransArrayCopy([]);
        firstProcess();
    };

    const getDefaultData = (e) => {
        setIsDefault(1);
        setToggleFilter(false);
        setTransArrayCopy(e);
        return;
    };

    const getUsers = async () => {
        try {
            let users = await store.getUsers();
            let clean_users = [];
            for (let i = 0; i < users.user.length; i++) {
                if (!isNaN(Number(users.user[i].IDNumber))) {
                    clean_users.push(users.user[i]);
                }
            }

            clean_users = clean_users.filter(item => Object.keys(item).includes('SignupDate'));
            const today = new Date();
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
            const startDate = firstDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
            const endDate = lastDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
            const strtNum = Number(`${startDate.slice(6, 10)}${startDate.slice(3, 5)}${startDate.slice(0, 2)}`);
            const endNum = Number(`${endDate.slice(6, 10)}${endDate.slice(3, 5)}${endDate.slice(0, 2)}`);

            const inRangeUsers = clean_users.filter((item) => {
                const itemDateNum = Number(`${item.SignupDate.slice(0, 4)}${item.SignupDate.slice(5, 7)}${item.SignupDate.slice(8, 10)}`);
                return itemDateNum >= strtNum && itemDateNum <= endNum;
            });

            setUsers(clean_users);
            setUserscopy(inRangeUsers);
        } catch (error) {
            console.error("Error in getUsers:", error);
        }
    };

    const generateReport = async () => {
        store.setLoading(true);
        if (await getImages() === true) {
            setReportGenerated(true);
        };
    };

    const getImages = async () => {
        const compLineIDs = CompanyTrans.map((item, i) => `${i}svg`);
        const compBarIDs = CompanyTrans.map((item, i) => `${i}barsvg`);
        const compPieIDs = CompanyTrans.map((item, i) => `${i}piesvg`);

        let imageObj = { ...ImageObj };
        let LineUris = [];
        let PieUris = [];
        let BarUris = [];
        let compGraphsDataObj = {
            Lines: [],
            Bars: [],
            Pies: {
                Images: [],
                Text: {
                    White: '',
                    Pink: ''
                }
            }
        };

        try {

            LineUris = await Promise.all(
                lineIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: 'transparent',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );
            compGraphsDataObj.Lines = await Promise.all(
                compLineIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: 'transparent',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );

            PieUris = await Promise.all(
                pieIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: '#0B132F',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );
            compGraphsDataObj.Pies.Images = await Promise.all(
                compPieIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: 'transparent',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );

            BarUris = await Promise.all(
                barIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: '#0B132F',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );
            compGraphsDataObj.Bars = await Promise.all(
                compBarIDs.map(async (item) => {
                    const chartSvg = document.getElementById(item);
                    if (chartSvg) {
                        const transImg = await svgAsPngUri(chartSvg, {
                            scale: 2,
                            format: 'webp',
                            quality: 0.01,
                            download: null,
                            ignore: '.ignored',
                            backgroundColor: 'transparent',
                            useCORS: true
                        });
                        return transImg;
                    }
                })
            );

            imageObj.signUps.Line = LineUris[0];
            imageObj.transactions.Line = LineUris[1];
            imageObj.failedTransactions.Line = LineUris[2];

            imageObj.signUps.Pie.Image = PieUris[0];
            imageObj.transactions.Pie.Image = PieUris[1];
            imageObj.failedTransactions.Pie.Image = PieUris[2];

            imageObj.signUps.Bar = BarUris[0];
            imageObj.transactions.Bar = BarUris[1];
            imageObj.failedTransactions.Bar = BarUris[2];

            imageObj.compTrans = compGraphsDataObj;

            console.log('imageObj : ', imageObj)
            setImageObj(imageObj);
            return true
        } catch (error) {
            console.error('Error in getImages:', error);
            return false
        }
    };

    return (
        <div className='reportParent'>
            <div className='datestring'>
                <img src={filter} onClick={toggleFilter} className='repImg' />
                <p className='reptext'>Showing results for <p className='p_'>{`${Dates.StartDate} - ${Dates.EndDate}`}</p> </p>
                <span className='genreport' onClick={generateReport}>Generate Report </span>
            </div>
            {ReportGenerated ? <div>
                <ReportingPDF
                    users={Userscopy}
                    convUsers={Users}
                    filterData={FilterData}
                    transactions={TransArrayCopy}
                    CompanyNames={CompanyNames}
                    CompanyDataArr={CompanyTrans}
                    images={ImageObj}
                    onPdfGenerated={handlePdfGenerated}
                />
            </div> : pdfUrl !== null ? <a href={pdfUrl} onClick={e => { setPdfUrl(null) }} download="report.pdf">Download PDF</a> : null}

            <div className='reportsection' >
                <div style={{ height: '100%', width: '98%', overflowY: 'flow' }} >
                    {ToggleFilter ?
                        <Filter users={Users} transactions={TransArray} onClose={toggleFilter}
                            filteredTrans={handleSubmit} getfilterData={getFilterData}
                            filterData={FilterData} onClear={e => handleOnclear()}
                            getFilteredUsers={e => { setUserscopy(e) }}
                            isDefault={IsDefaul} getDefaultDates={e => getDefaultData(e)}
                        />
                        : null
                    }

                    <SignUps users={Userscopy} filterData={FilterData} />
                    <Transactions className={`transactions`} filterData={FilterData} transactions={TransArrayCopy} CompanyNames={CompanyNames} getComTrans={e => { setCompanyTrans(e); setReportGenerated(false); }} />
                    <FailedTransactions className={`failedtransactions`} filterData={FilterData} transactions={TransArrayCopy.filter(trans => trans.Status === 'Failed')} />
                    {/* {TransArrayCopy.length > 0 ?
                        <Conversions Transactions={TransArrayCopy} users={Users} filterData={FilterData} /> : null
                    } */}
                </div>
            </div>
        </div>
    );
};

export default Reporting;
