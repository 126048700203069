import React from "react";
import './style.scss';

const FigureDisplay = ({ valueKeyPair, fomartValue, classname}) => {
    return (
        <div className={`figureDisplayParent ${classname}`}>
            <div className="figureDisplay">
                <span >{fomartValue ?`R ${Number(valueKeyPair.value  || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') }`: valueKeyPair.value}</span>
            </div>
            <p className="label"><span style={{textAlign:'left',marginLeft:'7%', width:'75%', fontSize:'80%'}}>{valueKeyPair.label}</span></p>
        </div>
    )
};
export default FigureDisplay;