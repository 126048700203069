import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import './style.scss';

const FailedHistogram = ({ data, name }) => {
  const svgRef = useRef(null);
  let yValue = 0;
  const [NumUsers, setNumUsers] = useState(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = svg.attr('width');
    const height = svg.attr('height');

    const margin = { top: 20, right: 5, bottom: 30, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const yDomain = [];
    let cnt = 0;
    for (let i = 0; i < data[0].length; i++) {
      if (data[0][i].value !== 0) {
        yDomain.push(data[0][i].value);
      }
      if (cnt >= 10) {
        yDomain.push(cnt);
        cnt = data[0][i].value;
      }
      if (yDomain.length < 1) {
        yDomain.push(10);
      }
    }

    const yScale = d3.scaleLinear()
      .domain([0, Math.max(...yDomain)])
      .range([innerHeight, 0]);

    const xScale = d3.scaleBand()
      .domain(data[0].map(d => d.label))
      .range([0, innerWidth])
      .padding(0.2);

    svg.selectAll("*").remove();

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const yAxis = d3.axisLeft(yScale)
      .ticks(Math.max(...yDomain) < 5 ? Math.max(...yDomain) : 5)
      .tickSize(0)
      .tickFormat(d3.format(".0s"));

    g.append('g')
      .call(yAxis)
      .selectAll('text')
      .attr('fill', 'rgba(255, 255, 255, 0.25)')
      .attr('font-size', '20px')
      .attr('transform', `translate(0,0)`);

    g.selectAll('path.domain').attr('stroke', 'rgba(255, 255, 255, 0.25)');

    const xAxis = d3.axisBottom(xScale)
      .tickSize(0)
      .tickFormat(d => d);

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .attr('fill', 'rgba(255, 255, 255, 0.25)')
      .attr('dy', '1.0em')
      .attr('font-size', '16px')
      .attr('dx', '-0.5em');

    g.selectAll('path.domain').attr('stroke', 'rgba(255, 255, 255, 0.25)');

    const roundedRect = (x, y, width, height, radius) => {
      return `M${x},${y + radius}
              Q${x},${y} ${x + radius},${y}
              L${x + width - radius},${y}
              Q${x + width},${y} ${x + width},${y + radius}
              L${x + width},${y + height}
              L${x},${y + height}Z`;
    };

    for (let i = 0; i < data.length; i++) {
      const dataset = data[i];
      const color = i === 0 ? 'white' : '#D42E79';
      const transformX = i === 0 ? 35 : 60;
      g.selectAll(`path-${i}`)
        .data(dataset)
        .enter()
        .append('path')
        .attr('d', d => d.value > 0 ?
          roundedRect(xScale(d.label) + transformX, yScale(d.value), xScale.bandwidth() - 85, innerHeight - yScale(d.value), 15) : '')
        .attr('fill', color)
        .on('mouseover', function (event, d) {
          const popup = document.querySelector('.failedbar_popup');
          yValue = d.value;
          console.log(xScale.bandwidth() )
          setNumUsers(`${yValue} Users`);
          popup.style.top = `${yScale(d.value) + 10.5}px`;
          popup.style.left = `${xScale(d.label) + xScale.bandwidth() + (i === 0 ? +120 : +150)}px`;
          popup.style.display = 'block';
          popup.style.height = '6.5%';
          popup.style.width = '5%';
          popup.style.cursor = 'pointer';
          popup.style.color = 'white';
          popup.style.background = '#ffffff40';
        })
      // .on('mouseout', function () {
      //   const popup = document.querySelector('.failedbar_popup');
      //   popup.style.display = 'none';
      //   setNumUsers('');
      // });
    }

  }, [data]);

  return (
    <div className='failedbar'>
      <p className='name'>{name}</p>
      <p className='failedbar_popup'>{NumUsers}</p>
      <svg id='failedTransBar' ref={svgRef} width={900} height={300} ></svg>
    </div>
  );
}
export default FailedHistogram;
