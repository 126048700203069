import React, { useEffect, useState, useRef } from 'react';
import { Page, Text, Image, Document, StyleSheet, pdf } from "@react-pdf/renderer";
import html2canvas from 'html2canvas';
import { useStore } from '../../../../stores/Store';
import Transactions from '../Transactions';
import FailedTransactions from '../FailedTransactions';
import CompanyTransactions from '../CompanyTransactions';
import Conversions from '../Conversions';
import Table from './Table';
import './style.scss';
import firstPage from '../images/firstPage.png';
import topImage from '../images/topImage.png';

const styles = StyleSheet.create({
    page: {
        padding: 0,
        backgroundColor: '#0B132F'
    },
    section: { marginBottom: 20 },
    image: { width: '100%', height: 'auto' },
    text: { fontSize: 12, marginBottom: 10, color: 'white' }
});

const ReportingPDF = ({ images, convUsers, filterData, transactions, CompanyNames, CompanyDataArr, onPdfGenerated }) => {
    const store = useStore();
    const [pdfUrl, setPdfUrl] = useState(null);
    const signUpsRef = useRef(null);
    const transactionsRef = useRef(null);
    const failedTransactionsRef = useRef(null);
    const conversionsRef = useRef(null);
    const compTransCanvasRefs = CompanyDataArr.map(() => React.createRef());
    const start = `${filterData.StartDate.slice(0, 2)}/${filterData.StartDate.slice(3, 5)}/${filterData.StartDate.slice(6, 10)}`;
    const end = `${filterData.EndDate.slice(0, 2)}/${filterData.EndDate.slice(3, 5)}/${filterData.EndDate.slice(6, 10)}`;

    useEffect(() => {
        const fetchData = async () => {
            try {
                store.setLoading(true);
                await handleCapture();
                if (pdfUrl && onPdfGenerated) {
                    onPdfGenerated(pdfUrl);
                };
            } catch (error) {
                console.error('Error in fetchData:', error);
            }
        };
        fetchData();
    }, [pdfUrl, onPdfGenerated]);

    const handleCapture = async () => {
        const signUpsCanvas = await html2canvas(signUpsRef.current);
        const transactionsCanvas = await html2canvas(transactionsRef.current);
        const failedTransactionsCanvas = await html2canvas(failedTransactionsRef.current);
        const conversionsCanvas = await html2canvas(conversionsRef.current);
        const compTransactionsCanvases = await Promise.all(compTransCanvasRefs.map(ref => html2canvas(ref.current)));

        const imageData = {
            signUps: signUpsCanvas.toDataURL('image/png'),
            transactions: transactionsCanvas.toDataURL('image/png'),
            failedTransactions: failedTransactionsCanvas.toDataURL('image/png'),
            conversions: conversionsCanvas.toDataURL('image/png'),
            compTransactions: compTransactionsCanvases.map(canvas => canvas.toDataURL('image/png'))
        };

        const PDFDocument = (
            <Document style={{ backgroundColor: '#0B132F' }}>
                <Page style={styles.page}>
                    <Image style={styles.image} src={firstPage} />
                    <Text style={{ color: 'white', padding: '2%', textAlign: 'center', width: '100%', position: 'absolute', top: '55%' }}>{`${start} - ${end}`}</Text>

                </Page>

                <Page style={styles.page}>
                    <Image style={styles.image} src={topImage} />
                    <Text style={{ color: 'white', padding: '2%', textAlign: 'right', width: '100%' }}>{`${start} - ${end}`}</Text>
                    {imageData.signUps && (
                        <>
                            <Image style={styles.image} src={imageData.signUps} />
                            <Image style={styles.image} src={imageData.transactions} />
                        </>
                    )}
                </Page>
                {imageData.compTransactions.length > 0 ?
                    imageData.compTransactions.map((img, index) => (
                        <Page style={styles.page}>
                            <Image style={styles.image} src={topImage} />
                            <Text style={{ color: 'white', padding: '2%', textAlign: 'right', width: '100%' }}>{`${start} - ${end}`}</Text>

                            <React.Fragment key={index}>
                                <Image style={styles.image} src={img} />
                            </React.Fragment>

                        </Page>
                    ))
                    : null
                }

                <Page style={styles.page}>
                    <Image style={styles.image} src={topImage} />
                    <Text style={{ color: 'white', padding: '2%', textAlign: 'right', width: '100%' }}>{`${start} - ${end}`}</Text>
                    {imageData.failedTransactions && (
                        <>
                            <Image style={styles.image} src={imageData.failedTransactions} />
                            <Image style={styles.image} src={imageData.conversions} />
                        </>
                    )}
                </Page>
            </Document>
        );

        const pdfBlob = await pdf(PDFDocument).toBlob();
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
    };


    return (
        <div className='pdf_parent' style={{
            position: 'absolute',
            top:'-999999px',left:'-9999999px',
            // top: '10%', left: '50px',
            background: '#0B132F', zIndex: '3'
        }}>
            <button onClick={handleCapture}>magic</button>
            <div ref={signUpsRef} className='comp_div'>
                <p className='singuptxt'>Sign Ups</p>
                <div className='linecomp' style={{ backgroundImage: `url(${images.signUps.Line})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>

                <p className='graph_desc_p'>Sign Ups Demographics</p>
                <div className='pieNbar_div'>
                    <div className='the_pie' style={{ backgroundImage: `url(${images.signUps.Pie.Image})` }} >
                        <div className='pieDescDiv'>
                            <p className='male_p'>{document.getElementById('whitesign').textContent.slice(0, 4)}<br />{document.getElementById('whitesign').textContent.slice(5)}</p>
                            <p className='female_p'>{document.getElementById('pinksign').textContent.slice(0, 6)}<br />{document.getElementById('pinksign').textContent.slice(7)}</p>
                        </div>
                    </div>

                    <div className='the_bar' style={{ backgroundImage: `url(${images.signUps.Bar})` }} ></div>
                </div>
            </div>

            <div ref={transactionsRef} className='comp_div'>
                <div className='figure_display'>
                    <div className='figure_aux'>
                        <Transactions classname={'figureDisplay2'} className={'TrasLine_inverse'} filterData={filterData} transactions={transactions} CompanyNames={CompanyNames} />
                    </div>
                </div>
                <div className='linecomp' style={{ backgroundImage: `url(${images.transactions.Line})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>

                <p className='graph_desc_p'> Transactions Demographics</p>
                <div className='pieNbar_div'>
                    <div className='the_pie' style={{ backgroundImage: `url(${images.transactions.Pie.Image})` }} >
                        <div className='pieDescDiv'>
                            <p className='male_p'>{document.getElementById('white').textContent.slice(0, 4)}<br />{document.getElementById('white').textContent.slice(5)}</p>
                            <p className='female_p'>{document.getElementById('pink').textContent.slice(0, 6)}<br />{document.getElementById('pink').textContent.slice(7)}</p>
                        </div>
                    </div>
                    <div className='the_bar' style={{ backgroundImage: `url(${images.transactions.Bar})` }} ></div>
                </div>
                <p className='token_ptag'>{`Total Stock Tokens Sold\t `}<span>{`: ${document.getElementById('reservedcoinspanID').textContent}`}</span></p>
            </div>

            {transactions.length > 0 && CompanyDataArr.map((trans, i) => (
                <div ref={compTransCanvasRefs[i]} key={i} className='comp_div' style={{ marginLeft: '0%' }}>
                    <div style={{ height: 'auto', width: '100%', background: '#d9d9d940', borderRadius: '15px', padding: '2.5%', marginTop: '5%' }}>
                        <p style={{ fontWeight: '600', fontSize: '36px', margin: '0px' }}>{trans.name}</p>
                        <div className='figure_display' style={{ height: '145px' }}>
                            <div className='figure_aux' style={{ top: '-57%' }}>
                                <CompanyTransactions id={i} filterData={filterData} transactions={trans.data} name={trans.name} classname={'figureDisplay2'} className={'comptransactions_inverse'} />
                            </div>
                        </div>

                        <div className='linecomp' style={{ background: '#0B132F', backgroundImage: `url(${images.compTrans.Lines[i]})`, backgroundSize: 'cover', backgroundPosition: 'center' }} > </div>

                        <p className='graph_desc_p'>Transactions Demographics</p>
                        <div className='pieNbar_div'>
                            <div className='the_pie' style={{ backgroundImage: `url(${images.compTrans.Pies.Images[i]})`, backgroundSize: `100% 85%` }} >
                                <div className='pieDescDiv'>
                                    <p className='male_p'>{document.getElementById(`${i}whitecomp`).textContent.slice(0, 4)}<br />{document.getElementById(`${i}whitecomp`).textContent.slice(5)}</p>
                                    <p className='female_p'>{document.getElementById(`${i}pinkcomp`).textContent.slice(0, 6)}<br />{document.getElementById(`${i}pinkcomp`).textContent.slice(7)}</p>
                                </div>
                            </div>
                            <div className='the_bar' style={{ backgroundImage: `url(${images.compTrans.Bars[i]})` }} ></div>
                        </div>
                        <p className='token_ptag'>{`Total Stock Tokens Sold\t `}<span>{`: ${document.getElementById(`${i}reservedcoinspanID`).textContent}`}</span></p>
                    </div>
                </div>
            ))}

            <div ref={failedTransactionsRef} className='comp_div'>
                <div className='figure_display' style={{ height: '205px', marginTop: '0px' }}>
                    <div className='figure_aux' style={{ top: '-8%' }}>
                        <FailedTransactions classname={'figureDisplay2'} className={'TrasLine_inverse'} filterData={filterData} transactions={transactions.filter(trans => trans.Status === 'Failed')} />
                    </div>
                </div>
                <div className='linecomp' style={{ backgroundImage: `url(${images.failedTransactions.Line})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                </div>

                <p className='graph_desc_p'> Failed Transactions Demographics</p>
                <div className='pieNbar_div'>
                    <div className='the_pie' style={{ backgroundImage: `url(${images.failedTransactions.Pie.Image})` }} >
                        <div className='pieDescDiv'>
                            <p className='male_p'>{document.getElementById('whitefailed').textContent.slice(0, 4)}<br />{document.getElementById('whitefailed').textContent.slice(5)}</p>
                            <p className='female_p'>{document.getElementById('pinkfailed').textContent.slice(0, 6)}<br />{document.getElementById('pinkfailed').textContent.slice(7)}</p>
                        </div>
                    </div>
                    <div className='the_bar' style={{ backgroundImage: `url(${images.failedTransactions.Bar})` }} ></div>
                </div>
            </div>

            <div ref={conversionsRef} className='comp_div' style={{ flexDirection: 'row' }}>
                <div className='table_cover'>
                    <p className='token_ptag'>Tokens Available </p>
                    <Table Companies={CompanyNames} />
                </div>

                <div className='conversion' >
                    <div className='conversion_top' >
                        <Conversions classname={'figureDisplay2'} filterData={filterData} Transactions={transactions} users={convUsers} />
                    </div>
                    <div className='conversion_bottom' >
                        <Conversions classname={'figureDisplay2'} filterData={filterData} Transactions={transactions} users={convUsers} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingPDF;
