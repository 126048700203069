import React, { useState, useEffect } from "react";
import './style.scss';
import { FigureDisplay } from '../../../../components';
import './style.scss';
import PieChart from "./PieChart";

const conversions_blocks = [1, 2, 3, 4];
const Conversions = ({ Transactions, users, filterData, classname }) => {
    const allTransactions = Transactions.filter(item => item.Status === 'Complete');
    const [Dates, setDates] = useState(filterData);
    const [PieData, setPieData] = useState([]);
    const [FigureData, setFigureData] = useState({
        S_to_I: { value: '', label: 'Sign ups to Investors' },
        EI_to_NI: { value: '', label: 'Existing to New Investors' },
        U_to_I: { value: '', label: 'User to Investors' },
        P_to_I: { value: '', label: 'Purchase to investors' },
    });

    useEffect(() => {
        if (Dates.EndDate) {
            if (Transactions.length > 0) {
                getDefaultData();
            };
        };
        if (Transactions.length > 0) {
            initialProcess();
        };
    }, [Transactions, users, filterData]);

    const getDefaultData = () => {
        if (Transactions.length > 0) {
            if (filterData.endDate === '00/00/0000') {
                let dates = { ...Dates };
                const today = new Date();
                const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                const startDate = firstDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');
                const endDate = lastDayOfMonth.toLocaleDateString('en-GB').replace(/\//g, '-');

                dates.StartDate = startDate.replace(/-/g, "/");
                dates.EndDate = endDate.replace(/-/g, "/");
                setDates(dates);
            };
        };
    };
    const initialProcess = () => {
        if (Transactions.length < 1) {
            return
        } else if (Transactions.length > 0 && users.length > 0) {
            let figureData = { ...FigureData };
            const completeTransArr = Transactions.filter(trans => trans.Status === 'Complete').map((trans) => trans.IDNumber).sort((a, b) => a - b);
            const userIDarr = users.map((user) => user.IDNumber).sort((a, b) => a - b);

            figureData.S_to_I.value = getPurUser_allUserRatio(completeTransArr, userIDarr);
            figureData.EI_to_NI.value = getExIn();
            figureData.U_to_I.value = getU2I();
            figureData.P_to_I.value = getP2I();
            setFigureData(figureData);
        }

    };
    const getP2I = () => {
        const purchase = Transactions.length;
        const investors = [... new Set(Transactions.map((trans) => trans.IDNumber))].length;

        getPieDataObj(investors, purchase, 'Investors', 'Purchase', 3);
        return `${((investors / purchase) * 100).toFixed(2)}%`
    };
    const getU2I = () => {
        if (users.length > 0) {
            if (Transactions.length > 0) {
                const userAr = users.length;
                let investors = []
                for (let i = 0; i < users.length; i++) {
                    for (let j = 0; j < allTransactions.length; j++) {
                        if (allTransactions[j].IDNumber === users[i].IDNumber) {
                            investors.push(allTransactions[j].IDNumber)
                        };
                    };
                };
                getPieDataObj([... new Set(investors)].length, userAr, 'Investors', 'User', 2);
                return `${(([... new Set(investors)].length / userAr) * 100).toFixed(2)}%`
            };
        };
    };
    const getExIn = () => {
        let existing = [];
        let newPurch = [];
        if (allTransactions.length > 0 && filterData.EndDate !== '00/00/0000') {
            const endDate = Number(new Date(`${filterData.EndDate.slice(6, 10)}-${filterData.EndDate.slice(3, 5)}-${filterData.EndDate.slice(0, 2)}`).toISOString().slice(0, 10).replace(/-/g, ''));
            let prevTransDates = allTransactions.map((item) => Number(item.Date.slice(0, 10).replace(/-/g, ''))).filter(item => item <= endDate);
            prevTransDates = prevTransDates.map((e) => `${String(e).slice(0, 4)}-${String(e).slice(4, 6)}-${String(e).slice(6, 8)}`);

            let B_to_E_Trans = allTransactions.filter((trans) => Number(trans.Date.slice(0, 10).replace(/-/g, '')) <= endDate);

            for (let i = 0; i < Transactions.length; i++) {
                let found = false;
                for (let j = 0; j < B_to_E_Trans.length; j++) {
                    if (Transactions[i].IDNumber === B_to_E_Trans[j].IDNumber) {
                        if (!existing.includes(Transactions[i].IDNumber)) {
                            existing.push(Transactions[i].IDNumber);
                        }
                        found = true;
                        break;
                    }
                };
                if (!found) {
                    if (!newPurch.includes(Transactions[i].IDNumber)) {
                        newPurch.push(Transactions[i].IDNumber);
                    };
                };
            };
            getPieDataObj(newPurch.length, existing.length, 'New', 'Existing', 1);
            return `${((newPurch.length / existing.length) * 100).toFixed(2)}%`
        };
    };
    const getPurUser_allUserRatio = (completeTransArr, userIDarr) => {
        let purchasedUsers = [];
        for (let i = 0; i < completeTransArr.length; i++) {
            for (let j = 0; j < userIDarr.length; j++) {
                if (completeTransArr[i] === userIDarr[j]) {
                    purchasedUsers.push(completeTransArr[i]);
                };
            };
        };
        getPieDataObj(purchasedUsers.length, users.length, 'Investors', 'Sign ups', 0);
        return `${((purchasedUsers.length / users.length) * 100).toFixed(2)}%`;
    };
    const getPieDataObj = (attr1, attr2, attr1name, attr2name, index) => {
        let pieData = [
            { label: attr1name, value: attr1, percentage: `${((attr1 / (attr1 + attr2)) * 100).toFixed(2)}%` },
            { label: attr2name, value: attr2, percentage: `${((attr2 / (attr1 + attr2)) * 100).toFixed(2)}%` },
        ];

        setPieData((prevPieData) => {
            prevPieData[index] = pieData;
            return [...prevPieData];
        });
    };

    return (
        <div className="conversions">
            <p className="heading">Conversions</p>
            <div className="conversions__figures">
                <FigureDisplay classname={`${classname}`} valueKeyPair={FigureData.S_to_I} />
                <FigureDisplay classname={`${classname}`} valueKeyPair={FigureData.EI_to_NI} />
                <FigureDisplay classname={`${classname}`} valueKeyPair={FigureData.U_to_I} />
                <FigureDisplay classname={`${classname}`} valueKeyPair={FigureData.P_to_I} />
            </div>

            <div className="conversions__container">
                {PieData.length ? conversions_blocks.map((item, index) => {
                    return (
                        <div key={index} className="conversion_pie_block">
                            <PieChart id={index} data={PieData[index]} width={900} height={800} key={index} />
                        </div>
                    );
                }) : null}
            </div>
        </div>
    );
};
export default Conversions;