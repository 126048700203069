import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './style.scss';

const PieChart = ({ data, width, height }) => {
    const ref = useRef();
    useEffect(() => {
        if (data && data.length > 0 && data.some(item => item.age.length > 0)) {
            const adjustedWidth = width * 0.9;
            const adjustedHeight = height * 0.9;
            const radius = Math.min(adjustedWidth, adjustedHeight) / 2;

            const svg = d3.select(ref.current)
                .attr('width', width)
                .attr('height', height)
                .attr('viewBox', `0 0 ${width} ${height}`)
                .append('g')
                .attr('transform', `translate(${width / 2}, ${height / 2})`);

            const color = d3.scaleOrdinal()
                .domain(['Male', 'Female'])
                .range(['#FFFFFF', '#D42E79']);

            const pie = d3.pie()
                .value(d => d.value);

            const arc = d3.arc()
                .innerRadius(0.5)
                .outerRadius(radius)
                .padAngle(0.02); // Add padding between arcs

            const arcs = svg.selectAll('.arc')
                .data(pie(data))
                .enter()
                .append('g')
                .attr('class', 'arc');

            arcs.append('path')
                .attr('d', arc)
                .attr('fill', d => color(d.data.label));

            arcs.append('text')
                .attr('transform', d => {
                    const [x, y] = arc.centroid(d);
                    let maleDesc = document.getElementById('whitefailed');
                    maleDesc.style.background = 'none'
                    maleDesc.style.height = 'auto'
                    maleDesc.style.width = 'auto'
                    maleDesc.style.color = 'black'
                    maleDesc.style.transform = `translate(${x + 175}px,${y + 200}px)`

                    let femaleDesc = document.getElementById('pinkfailed');
                    femaleDesc.style.background = 'none'
                    femaleDesc.style.height = 'auto'
                    femaleDesc.style.width = 'auto'
                    femaleDesc.style.color = 'black'
                    femaleDesc.style.transform = `translate(${x + 165}%,${y + 120}%)`
                    return `translate(${x}, ${y + 2})`;
                })
            return () => {
                d3.select(ref.current).selectAll('*').remove();
            };
        }
    }, [data, width, height]);

    return (
        <div id ='failedTransPiDiv_ID' className='failedpie'>
            <p style={{ position: 'absolute' ,top:'-12%',left:'1%' }} id={`whitefailed`}>{data.length > 0 && data[0].percentage ? data[0].label : null} <br />{data.length > 0 ? data[0].percentage : null}</p>
            <p style={{ position: 'absolute' ,top:'0%',left:'0.5%' }} id={`pinkfailed`}>{data.length > 0 && data[1].percentage ? data[1].label : null} <br />{data.length > 0 ? data[1].percentage : null}</p>

            <svg id ='failedTransPie_Id' style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} ref={ref}></svg>
        </div>
    );
};

export default PieChart;