import React, { useEffect, useRef,useState } from 'react';
import * as d3 from 'd3';
import './style.scss';

const LineGraph = ({ width, height, data, name }) => {
  const svgRef = useRef();
  const [NumUsers, setNumUsers] = useState(null);

  let yValue = 0;
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const { width: svgWidth, height: svgHeight } = svg.node().getBoundingClientRect();
    const margin = { top: 20, right: 30, bottom: 30, left: 60 };
    const innerWidth = svgWidth - margin.left - margin.right;
    const innerHeight = svgHeight - margin.top - margin.bottom;

    const xDomain = d3.range(data.length);
    const yDomain = data.map(d => d.Amount);

    const xScale = d3.scaleLinear()
      .domain([0, data.length - 1])
      .range([0, innerWidth]);

    const yScale = d3.scaleLinear()
      .domain([0, Math.max(...yDomain) * 1])
      .range([innerHeight, 0]);

    const line = d3.line()
      .curve(d3.curveLinear)
      .x((d, i) => xScale(i))
      .y(d => yScale(d.Amount));

    svg.selectAll("*").remove();

    const g = svg.append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // Draw y-axis
    const yAxis = d3.axisLeft(yScale)
      .ticks(5)
      .tickSize(0)
      .tickFormat(d3.format(".0s"));

    g.append('g')
      .call(yAxis)
      .selectAll('text')
      .attr('fill', 'rgba(255, 255, 255, 0.25)')
      .attr('font-size', '20px')
      .attr('transform', `translate(0,0)`);

    g.selectAll('path.domain').attr('stroke', 'rgba(255, 255, 255, 0.25)');

    // Draw x-axis
    const xAxis = d3.axisBottom(xScale)
      .ticks(data.length - 1)
      .tickSize(0)
      .tickFormat((d, i) => data[i].day);

    g.append('g')
      .attr('transform', `translate(0,${innerHeight})`)
      .call(xAxis)
      .selectAll('text')
      .attr('fill', 'rgba(255, 255, 255, 0.25)')
      .attr('dy', '1em')
      .attr('font-size', '16px')
      .attr('dx', '0.8em');

    g.selectAll('path.domain').attr('stroke', 'rgba(255, 255, 255, 0.25)');

    // Draw line
    g.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', 'rgba(212, 46, 121, 1)')
      .attr('stroke-width', 2)
      .attr('d', line);

    // Draw circles for data points
    g.selectAll('.data-point')
      .data(data)
      .enter().append('circle')
      .attr('class', 'data-point')
      .attr('cx', (d, i) => xScale(i))
      .attr('cy', d => yScale(d.Amount))
      .attr('r', 10)
      .attr('fill', 'transparent')
      .on('mouseover', function (event, d, i) {
        const pass_popup = document.querySelector('.pass_popup');
        yValue = d.Amount;

        // Get the position of the circle
        const circle = event.target;
        const circleRect = circle.getBoundingClientRect();
        const svgRect = svgRef.current.getBoundingClientRect();

        // Calculate the popup's position relative to the circle
        const popupLeft = circleRect.left - 15 - svgRect.left + 'px';
        const popupTop = circleRect.top +30- svgRect.top + 'px';
        
        setNumUsers(`R${yValue.toFixed(2)}`);
        pass_popup.style.top = popupTop;
        pass_popup.style.left = popupLeft;
        pass_popup.style.display = 'block';
        pass_popup.style.height = 'auto';
        pass_popup.style.width = 'auto';
        pass_popup.style.cursor = 'pointer';
        pass_popup.style.color = 'white';
        pass_popup.style.background = '#ffffff40';

      })
      .on('mouseout', function () {
        const pass_popup = document.querySelector('.pass_popup');
        pass_popup.style.display = 'none';
        setNumUsers('');
      });

  }, [data]);

  return (
    <div className='trans_line'>
      <p className='_pgraphname'>{name}</p>
      <p className='pass_popup' >{NumUsers} </p>
      <svg id ='TransLineID' ref={svgRef} width={'95%'} height={height}></svg>
    </div>
  );
};

export default LineGraph;
